@import './vars';
@import './mixins';

.EditAddOptions {
  &-AddButton {
    margin-top: $standard-gap / 2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $control-secondary-border-radius;
    height: $control-height;
    background-color: $elements-dark-bg-color;
    color: $secondary-bg-color;
    font-size: $control-height * 0.6;
    width: 100px;
    border: none;

    &:hover {
      background-color: $secondary-bg-color;
      color: $elements-dark-bg-color;
    }
  }
}
