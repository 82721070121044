@import './vars';
@import './mixins';

.PaginationBlock {
  height: $pagination-height;
  display: flex;
  justify-content: center;
  align-items: center;

  @include onDesktop {
    height: $pagination-height-desktop;
  }

  .pagination {
    margin-bottom: 0 !important;
  }

  &-More>.page-link {
    &:hover {
      cursor: initial !important;
    }
  }
}

.page-item {
  //z-index: -1;
}

.page-link {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
}
