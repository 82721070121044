@import './vars';
@import './mixins';

.PaymentStatusBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: $standard-gap / 2;
  padding-right: $standard-gap / 2;
  min-width: 100px;
  border-top-left-radius: $control-border-radius;
  border-bottom-left-radius: $control-border-radius;
  height: $standard-gap * 1.5;
  color: $light-font-color;
  font-size: $small-font-size;
  line-height: $standard-gap * 1.5;

  &-Awaiting {
    background: linear-gradient(90deg, $elements-dark-bg-color, $secondary-bg-color);
  }

  &-Cancelled {
    background-color: $secondary-bg-color;
  }

  &-Paid {
    background-color: $elements-dark-bg-color;
  }
}
