@import './vars';
@import './mixins';

.Footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $secondary-bg-color;
  height: $footer-height;
  padding: $standard-gap / 4 0;

  @include onDesktop {
    display: none;
    height: $footer-height-desktop;
  }

  &-Content {
    margin: 0 auto;
    min-width: $content-min-width;
    width: $content-width;
    max-width: $content-max-width;
    height: 100%;
    display: flex;
    align-items: center;

    @include onDesktop {
      width: $content-max-width;
    }
  }

  &-Nav {
    min-width: $content-min-width;
    width: $content-width;
    max-width: $content-max-width;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.NavMenuFooter {
  min-width: $content-min-width;
  width: $content-width;
  max-width: $content-max-width;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-Item {
    //padding: 10px;
    text-decoration: none;
    font-size: $small-font-size;
    color: $light-font-color;
    display: flex;
    flex-direction: column;

    &_Active {
      color: $elements-dark-bg-color;
    }
  }

  &-Icon {
    height: 0.4 * $footer-height;

    &_Notepad {
      background: url('../../public/img/footer-menu/notepad-icon.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &_Trips {
      background: url('../../public/img/footer-menu/trips-icon.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &_Passengers {
      background: url('../../public/img/footer-menu/passengers-icon.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &_Exchange {
      background: url('../../public/img/footer-menu/change-icon.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &_Profile {
      background: url('../../public/img/footer-menu/profile-icon.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.NavMenuFooter-Item_Active .NavMenuFooter-Icon_Notepad {
  background: url('../../public/img/footer-menu/notepad-icon-active.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.NavMenuFooter-Item_Active .NavMenuFooter-Icon_Trips {
  background: url('../../public/img/footer-menu/trips-icon-active.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.NavMenuFooter-Item_Active .NavMenuFooter-Icon_Passengers {
  background: url('../../public/img/footer-menu/passengers-icon-active.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.NavMenuFooter-Item_Active .NavMenuFooter-Icon_Exchange {
  background: url('../../public/img/footer-menu/change-icon-active.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.NavMenuFooter-Item_Active .NavMenuFooter-Icon_Profile {
  background: url('../../public/img/footer-menu/profile-icon-active.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
