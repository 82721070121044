@import './vars';
@import './mixins';

.MyWallet {
  padding-top: $standard-gap;
  //min-height: calc(100vh - #{$header-height + $footer-height});
  //
  //@include onTablet {
  //  min-height: calc(100vh - #{$header-height-tablet + $footer-height-tablet});
  //}
  //
  //@include onDesktop {
  //  min-height: calc(100vh - #{$header-height-desktop});
  //}

  &-Title {
    display: none;

    @include onDesktop {
      display: block;
    }
  }

  &-TransactionsSummary {
    margin: 0 auto $standard-gap;
  }

  &-ButtonsBlock {
    margin: 0 auto;
    min-width: $content-min-width;
    width: $content-width;
    max-width: $content-max-width;
  }

  &-ButtonContainer {
    margin-bottom: $standard-gap;
  }

  &-AddToBalance {
    margin: 0 auto;
  }
}
