@import './vars';
@import './mixins';

.Header {
  width: 100%;
  height: $header-height;
  background-color: $elements-light-bg-color;
  box-sizing: border-box;

  @include onTablet {
    height: $header-height-tablet;
  }

  @include onDesktop {
    background-color: transparent;
    height: $header-height-desktop;
  }

  &-Auth {
    height: 0.6 * $control-height;

    @include onDesktop {
      height: $control-height;
    }
  }

  &_Dark {
    background-color: $secondary-bg-color;
  }

  &-Content {
    margin: 0 auto;
    width: $content-width;
    max-width: $content-max-width;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include onTablet {
      max-width: 100%;
    }

    @include onDesktop {
      width: $content-max-width-desktop;
      max-width: $content-max-width-desktop;
    }
  }

  &-Left {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-grow: 1;
  }

  &-AppName {
    padding-left: $standard-gap / 2;
    text-transform: uppercase;
    //color: $light-font-color;

    @include onDesktop {
      color: $light-font-color;
      padding-left: $standard-gap-desktop / 2;
      padding-right: $standard-gap-desktop;
    }
  }

  &-Nav {
  }

  &-Right {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  &-Lang {
    box-sizing: border-box;
    padding: 2px !important;
    font-size: $small-font-size !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    //background-color: $elements-dark-bg-color !important;
    background-color: $main-font-color !important;
    color: $light-font-color;
    border: 1px solid $light-font-color !important;
    border-radius: $control-secondary-border-radius !important;
    width: $control-height * 0.8;
    height: $control-height * 0.6;

    @include onDesktop {
      padding: 5px !important;
      font-size: $small-font-size-desktop !important;
      min-width: $control-height;
      width: $control-height;
      height: $control-height;
    }

    &:hover {
      background-color: $elements-dark-bg-color !important;
    }
  }

  &-Auth {
    border: 1px solid $elements-light-bg-color;
    background-color: transparent;
    border-radius: $control-secondary-border-radius;
    max-width: 70px;
    text-transform: uppercase;
    margin-left: $standard-gap;
    display: none;

    @include onDesktop {
      display: block;
      max-width: 100px;
      margin-left: $standard-gap-desktop;
    }

    &:hover {
      background-color: $elements-dark-bg-color;
    }
  }

  &-Title-Container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;

    @include onDesktop {
      display: none;
    }
  }

  &-BackButton {
    margin-left: $standard-gap / 2;
    margin-right: $standard-gap / 2;
  }

  &-DesktopBackButton {
    display: none;
    width: $control-height !important;
    height: $control-height !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: $standard-gap;

    &:hover {
      cursor: pointer;
    }

    //@include onDesktop {
    //  display: block;
    //}


    @media (min-width: 1280px) and (max-width: 1440px) {
      left: 0;
    }
  }

  &-Title {
    color: $light-font-color;
    text-align: center;
  }

  &-NewIndicator {
    position: absolute;
    top: 50%;
    right: $standard-gap / 8;
    transform: translateY(-50%);

    @include onTablet {
      right: $standard-gap / 2;
      font-size: $main-font-size * 1.2;
    }

    @include onDesktop {
      right: $standard-gap;
      font-size: $main-font-size * 1.5;
    }
  }
}

.BackArrow {
  width: 16px;
  height: 22px;
  background: url('../../public/img/back-arrow-icon-white.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
