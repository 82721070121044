@import './vars';
@import './mixins';

.SearchTripOrder {
  padding-top: $standard-gap;
  min-width: $content-min-width;
  width: $content-width;
  max-width: $content-max-width;

  &-Item {
    margin-bottom: $standard-gap;
    width: 100%;
    align-items: center;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
      width: $content-width;
      max-width: $content-max-width;
    }
  }

  &-Label {
    display: block;
    margin-bottom: $standard-gap / 2;
    text-align: left;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop / 2;
    }
  }

  &-InputContainer {
    position: relative;
  }

  &-Input {
    padding-left: 44px;
  }

}
