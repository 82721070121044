@import './vars';
@import './mixins';

.InvitationCard {
  &.TripCard {
    &:hover {
      cursor: initial;
    }
  }

  &-Info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $control-height;
    background-color: $elements-dark-bg-color;
    color: $light-font-color;
    text-align: center;
    font-weight: $bold-font-weight;

    &_Contrast {
      background-color: $secondary-bg-color;
    }
  }
}
