@import './vars';
@import './mixins';

.InviteDriver {
  min-width: $content-min-width;
  width: $content-width;
  max-width: $content-max-width;
}

.InviteDriverForm {
  &-Item {
    position: relative;
    margin-bottom: $standard-gap;
    width: 100%;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
      width: $content-width;
      max-width: $content-max-width;
    }
  }

  &-Label {
    display: block;
    margin-bottom: $standard-gap / 2;
    text-align: left;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop / 2;
    }
  }

  &-InputContainer {
    position: relative;
  }

  &-Input {
    padding-left: 44px;
  }

  &-InputIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }
}
