@import './vars';
@import './mixins';

.InputWithLoader {
  position: relative;

  &_Loader {
    position: absolute;
    top: 50%;
    right: $standard-gap / 2;
    transform: translateY(-50%);
  }
}
