@import './styles/_vars.scss';
@import './styles/_mixins.scss';
@import './styles/Common.scss';

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

body {
  margin: 0;
  font-family: $main-font-family;
  font-size: $main-font-size;
  line-height: 1.25em;
  font-weight: $main-font-weight;
  color: $main-font-color;
  box-sizing: border-box;

  @include onDesktop {
    font-size: $main-font-size-desktop;
  }
}

.root {
  box-sizing: border-box;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-color: $secondary-bg-color #ffffff;
  scrollbar-width: thin;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: $scroll-bar-width;
  height: 8px;
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: $secondary-bg-color;
  border-radius: 4px;
  border: 2px solid #ffffff;
}

.scale {
  transition: transform 0.3s linear;

  &:hover {
    transform: scale(1.04);
  }
}
