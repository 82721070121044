@import './vars';
@import './mixins';

.TransactionsSummary {
  min-width: $content-min-width;
  width: $content-width;
  max-width: $content-max-width;
  border-radius: $control-secondary-border-radius;
  border: 2px solid $secondary-bg-color;

  &-Item {
    padding: $standard-gap / 4;
    border-bottom: 1px solid $additional-bg-color;
    &:last-child {
      border-bottom: none;
    }
  }

  &-Amount {
    color: $elements-dark-bg-color;

    &_Negative {
      color: red;
    }
  }
}
