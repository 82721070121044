@import './vars';
@import './mixins';

.FormControl {
  box-sizing: border-box;
  width: $control-width;
  height: $control-height;
  padding: 10px;
  border: 1px solid $pale-font-color;
  border-radius: $control-border-radius;
  color: $pale-font-color;
  font-weight: $pale-font-weight;
  font-size: $small-font-size;

  &:focus {
    outline: none;
    border: 2px solid $main-font-color;
  }

  &_Error {
    border: 1px solid red;
  }

  @include onDesktop {
    font-size: $small-font-size-desktop;
  }
}

.RegularButton {
  box-sizing: border-box;
  width: $control-width;
  height: $control-height;
  padding: 10px;
  background-color: $elements-dark-bg-color;
  font-weight: $main-font-weight;
  font-size: $main-font-size;
  border: none;
  border-radius: $control-border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $light-font-color;
  transition: 0.2s;

  &:hover {
    cursor: pointer !important;
    transform: scale(1.01);
  }

  &:active {
    transform: scale(0.99);
  }

  &_Contrast {
    background-color: $secondary-bg-color;
    color: $light-font-color;
  }

  //&_Danger {
  //  color: $main-font-color;
  //  background-color: $main-bg-color;
  //  border: 1px solid $main-font-color;
  //}

  &_Pale {
    background-color: $elements-pale-bg-color;
  }

  &_Centered {
    max-width: $content-max-width;
    margin: 0 auto;
  }

  @include onDesktop {
    //font-size: $main-font-size-desktop;
  }
}

.RegularTitle {
  font-weight: $title-font-weight;
  font-size: $large-font-size;
  line-height: 1.4em;
  text-align: center;

  @include onDesktop {
    font-size: $large-font-size-desktop;
  }

  &_Small {
    font-size: $main-font-size;

    @include onDesktop {
      font-size: $main-font-size-desktop;
    }
  }

  &_Tiny {
    font-size: $small-font-size;

    @include onDesktop {
      font-size: $small-font-size-desktop;
    }
  }

  &_Left {
    text-align: left;
  }

  margin-bottom: $standard-gap / 2;
}

.Logo {
  width: 30px;
  height: 30px;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }

  @include onTablet {
    width: 60px;
    height: 60px;
  }

  @include onDesktop {
    width: 80px;
    height: 80px;
  }
}

.NavMenu {
  display: none;

  @include onDesktop {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-Item {
    padding: 5px;
    text-decoration: none;
    color: $light-font-color;

    &_Active {
      color: $elements-dark-bg-color;
    }
  }

  &-IconForAdmin {
    font-size: 24px;
  }
}

.Notification {
  display: flex;
  align-items: center;
  border-radius: $control-border-radius;
  background-color: $additional-bg-color;
}

.AlertNotification {
  color: $elements-dark-bg-color;
  font-size: $small-font-size;
}

.RadioFieldSet {
  padding: 0;
  border: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: $control-height;

  &-Input {
    width: 1px !important;
    height: 1px !important;
    opacity: 0;
  }

  &-Label {
    width: 45%;
    height: 100%;
    background-color: $elements-light-bg-color;
    color: $main-font-color;
    border: 1px solid $pale-font-color;
    border-radius: $control-border-radius;
    //box-shadow: 4px 4px 10px rgba(0, 71, 255, 0.5);
    text-align: center;
    line-height: $control-height;

    &:hover {
      cursor: pointer;
    }

    &_Disabled {
      background-color: $standard-border-color;
    }

    &_Active {
      border: none;
      background-color: $elements-dark-bg-color;
      color: $elements-light-bg-color;
    }
  }
}

.Avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: $additional-bg-color;
  object-fit: cover;
  object-position: center;
}

.FieldLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: $additional-bg-color;

  &:hover {
    cursor: pointer;
  }
}

.Input-Photo {
  display: none;
}

.Phone-Container {
  width: $control-width;
  margin: 0 auto;
}

.SeparationBlock {
  width: 100vw;
  height: 1px;
  background-color: $additional-bg-color;

  &_Limited {
    width: 100%;
  }

  &_ConsideringScroll {
    width: calc(100vw - #{$scroll-bar-width});
  }
}

//REGISTRATION FORM

.PhoneRow {
  //margin-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  //gap: 10px;
  max-width: 100%;
}

.PhoneAddRemoveButtons {
  position: absolute;
  top: 50%;
  right: $standard-gap / 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 0.8 * $control-height;
  height: 0.8 * $control-height;
  gap: 2px;
  transform: translateY(-50%);
  //gap: 5px;
}

.PhoneAddButton {
  box-sizing: border-box;
  display: inline-block;
  border: 2px solid $main-font-color;
  border-radius: 4px;
  font-size: 20px;
  line-height: 20px;
  font-width: 800;
  position: relative;
  z-index: 2;
  color: $main-font-color;
  letter-spacing: .2px;
  text-transform: uppercase;
  width: 50px;
  //height: 25px !important;
  flex-grow: 1 !important;

  &:hover {
    background-color: $main-font-color !important;
    color: $light-font-color !important;
  }
}

.EditButton {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 2px solid $secondary-bg-color;
  color: $light-font-color;
  width: 0.4 * $control-height;
  height: 0.4 * $control-height;
  background-color: $elements-light-bg-color;
  padding: 0;

  &:hover {
    background-color: $elements-dark-bg-color;
  }
}

.EditIconInTable {
  width: 0.28 * $control-height;
  height: 0.28 * $control-height;
}

.DelButton {
  box-sizing: border-box;
  display: inline-block;
  border-radius: 4px;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: .2px;
  text-transform: uppercase;
  border: 2px solid red;
  color: red;
  width: 0.4 * $control-height;
  height: 0.4 * $control-height;
  background-color: $elements-light-bg-color;
  padding: 0;
  background-color: $elements-light-bg-color;

  &:hover {
    background-color: red;
    color: $light-font-color;
  }
}

.ShowButton {
  box-sizing: border-box;
  display: inline-block;
  border-radius: 4px;
  font-size: 10px;
  line-height: 10px;
  position: relative;
  z-index: 2;
  letter-spacing: .2px;
  text-transform: uppercase;
  border: 2px solid $elements-dark-bg-color;
  color: $elements-dark-bg-color;
  width: 0.4 * $control-height;
  height: 0.4 * $control-height;
  background-color: $elements-light-bg-color;
  padding: 0;

  &:hover {
    background-color: $elements-dark-bg-color;
    color: $light-font-color;
  }
}

.ConfirmButton {
  box-sizing: border-box;
  display: inline-block;
  border-radius: 4px;
  font-size: 10px;
  line-height: 10px;
  position: relative;
  z-index: 2;
  letter-spacing: .2px;
  text-transform: uppercase;
  border: 2px solid green;
  color: green;
  width: 0.4 * $control-height;
  height: 0.4 * $control-height;
  background-color: $elements-light-bg-color;
  padding: 0;

  &:hover {
    background-color: green;
    color: $light-font-color;
  }
}

.react-tel-input {
  //min-width: 180px;
  max-width: 100%;
  flex-grow: 1;
}

.form-control {
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  height: $control-height;
  padding: 10px;
  border: 1px solid $pale-font-color !important;
  border-radius: $control-border-radius !important;
  color: $pale-font-color;
  font-weight: $pale-font-weight;
  font-size: $small-font-size;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 2px solid $main-font-color !important;
  }

  @include onDesktop {
    font-size: $small-font-size-desktop;
  }
}

.PhoneRow_Error .form-control {
  border: 1px solid red !important;
}

.react-tel-input {
  width: 100%;
}

.react-tel-input .form-control {
  flex-grow: 1;
  width: 100% !important;
  max-width: $content-max-width;
  min-height: $control-height !important;
}

.flex-row {
  justify-content: center;
}

//OTHER

.nav-item {
  min-width: 180px;
  text-align: center;
  background-color: $additional-bg-color;
  border-radius: $control-secondary-border-radius;
}

.nav-link {
  padding-left: $standard-gap / 4 !important;
  padding-right: $standard-gap / 4 !important;
}

.nav-pills {
  border-radius: $control-secondary-border-radius;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: $secondary-bg-color !important;
}

.RulesLinkContainer {
  display: flex;
  align-items: center;
  gap: $standard-gap / 2;

  &-CheckBox {
    width: $standard-gap;
    height: $standard-gap;

    &:hover {
      cursor: pointer;
    }
  }

  &-Text {
    text-align: left;
    color: $main-font-color;
    font-size: $small-font-size * 0.8;
    line-height: 1.2em;
  }

  &-Link {
    &:hover {
      color: $elements-dark-bg-color;
    }

    @include onDesktop {
      text-decoration: none;
    }
  }
}

.CheckboxWithText {
  display: flex;
  justify-content: left;
  align-items: center;

  &-CheckBox {
    width: 15px;
    height: 15px;
  }

  &-Text {
    padding-left: 10px;
    text-align: left;
    color: $main-font-color;
    font-size: $small-font-size * 0.8 !important;
    line-height: 1.2em;
  }
}

.FieldsContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.FlexBlock {
  display: flex;
  justify-content: space-between;

  &_Columns {
    flex-direction: column;

    @include onDesktop {
      flex-direction: row;
    }

    &_OnDesktopToo {
      @include onDesktop {
        flex-direction: column;
      }
    }
  }

  &_JustifyLeft {
    justify-content: left;
  }

  &_SpaceAround {
    justify-content: space-around;
  }

  &_JustifyCenter {
    justify-content: center;
  }

  &_AlignCenter {
    align-items: center;
  }

  &-HorizontalItem {
    width: 50%;

    @for $size from 5 through 100 {
      &_W#{$size} {
        width: percentage($size / 100);
      }
    }
  }
}

.OptionCheckBox {
  margin-bottom: 0;
  width: $checkbox-size;
  height: $checkbox-size;

  &:hover {
    cursor: pointer;
  }
}

.OptionText {
  padding-left: $standard-gap / 2;

  &:hover {
    cursor: pointer;
  }

  &_Bold {
    font-weight: $bold-font-weight;
  }
}

.TextArea {
  min-height: $control-height * 2;
}

.ColoredText {
  color: $elements-dark-bg-color;
}

.css-1xc3v61-indicatorContainer {
  &:hover {
    cursor: pointer;
  }
}

.TabControls {
  padding-top: $standard-gap / 2;

  @include onDesktop {
    padding-top: 0;
  }

  &-AddButtonContainer {
    display: none;

    @include onDesktop {
      display: initial;
    }

    &_Right {
      padding-left: $standard-gap / 4;
    }

    &_Left {
      padding-right: $standard-gap / 4;
    }
  }

  &-AddButton {
    display: none;

    @include onDesktop {
      display: initial;
      max-height: $standard-gap * 2;
      padding: $standard-gap / 4;
      min-width: 180px;
    }
  }
}

.TabContent {
  padding-top: $standard-gap / 2;
}

.PaddingBlock {
  padding-top: $standard-gap;

  &_Half {
    padding-top: $standard-gap / 2;
  }
}

.ListContainer {
  //display: flex;
  //align-items: center;
  flex-grow: 1;
  height: 70vh;
  max-height: 70vh;
  overflow-y: auto;
  box-sizing: border-box;

  @include onDesktop {
    width: $content-max-width-desktop;
    height: 65vh;
    max-height: 65vh;
  }

  &_Extended {
    height: 75vh;
    max-height: 75vh;
  }

  &_Reduced {
    height: 62vh;
    max-height: 62vh;

    @include onDesktop {
      height: 60vh;
      max-height: 60vh;
    }
  }

  &_SharplyReduced {
    height: 52vh;
    max-height: 52vh;

    @include onDesktop {
      height: 55vh;
      max-height: 55vh;
    }
  }

  &_ExtremelyReduced {
    max-height: 38vh;

    @include onDesktop {
      max-height: 35vh;
    }
  }
}

.RouteBlock {
  //display: flex;
  //justify-content: left;
  //align-items: center;
  color: $elements-dark-bg-color;
}

.DesktopTitle {
  display: none;

  @include onDesktop {
    display: block;
    text-align: center;
  }
}

.RadioItem {
  padding-bottom: $standard-gap / 2;

  & :hover {
    cursor: pointer;
  }
}


.CardsList {
  //width: 100%;
  display: grid;
  gap: $standard-gap;
  grid-template-columns: repeat(auto-fill, minmax($content-min-width, 1fr));

  @include onDesktop {
    //width: $content-max-width-desktop - 10px;
    //width: $content-max-width-desktop;
    max-width: $content-max-width-desktop;
  }

  &-AddButton {
    position: fixed;
    bottom: $footer-height + $standard-gap * 4;
    min-width: $content-min-width;
    width: $content-width;
    max-width: $content-max-width;
    z-index: 99;

    @include onTablet {
      bottom: 20vh;
    }

    @include onDesktop {
      display: none;
      //left: 50%;
      //transform: translateX(-50%);
      //bottom: $standard-gap * 5;
      //
      //&:hover {
      //  transform: translateX(-50%) scale(1.01);
      //}
    }
  }
}

.TripCard {
  position: relative;
  box-sizing: border-box;
  //padding: $standard-gap / 2;
  min-width: $content-min-width;
  max-width: $content-max-width;
  border-radius: $control-secondary-border-radius;
  border: 2px solid $elements-dark-bg-color;
  height: 100%;

  &:hover {
    cursor: pointer;
  }

  @include onDesktop {
    min-width: $content-min-width;
  }

  &-HeadBlock {
    padding: $standard-gap / 4 $standard-gap / 2;
    //flex-grow: 1;
    text-align: center;
    font-weight: $bold-font-weight;
  }

  &-DateBlock {
    min-width: 145px;
    background-color: $secondary-bg-color;
    border-bottom-right-radius: $control-secondary-border-radius;
    color: $light-font-color;
  }

  &-PriceBlock {
    color: $elements-dark-bg-color;
  }

  &_Cancelled {
    border: 2px solid $secondary-bg-color;
    background-color: $additional-bg-color;
  }

  &-Item {
    margin-bottom: $standard-gap / 4;
  }

  &-DateText {
    font-size: $main-font-size;
  }

  &-PlacesAmountBlock {

  }

  //&-TripTypeBlock {
  //  font-size: $large-font-size;
  //  line-height: $large-font-size;
  //}

  &-TripTypeBlock {
  }

  &-MainSection {
    position: relative;
    padding: $standard-gap / 4 $standard-gap / 2;
    font-weight: $bold-font-weight;
    font-size: $main-font-size * 1.1;
  }

  &-Block {
    padding-top: $standard-gap / 4;
    padding-bottom: $standard-gap / 4;
    min-height: $standard-gap * 2;
  }

  &-RouteBlock {
    display: flex;
    justify-content: left;
    align-items: center;
    vertical-align: center;
    color: $elements-dark-bg-color;
    max-width: 72%;
  }

  &-CompanyNameBlock {

  }

  &-DriversBlock {

  }

  &-OptionsBlock {
    color: $elements-dark-bg-color;
    gap: $standard-gap / 2;
    font-size: $large-font-size;
  }

  &-TripTypeIcon {
    position: absolute;
    bottom: 0;
    right: $standard-gap / 2;
    font-size: $large-font-size * 3;
    color: $elements-dark-bg-color;
  }

  &-BadgeNew {
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-left-radius: $control-secondary-border-radius;
    min-width: 10%;
    background-color: $elements-dark-bg-color;
    color: $light-font-color;
    padding: 5px;
  }

  &-EditButton {
    position: absolute;
    top: $standard-gap / 2;
    right: $standard-gap;

    &:hover {
      cursor: pointer;
    }
  }

  &-CancelledTripIcon {
    position: absolute;
    bottom: 0;
    right: $standard-gap / 2;
    font-size: $large-font-size * 3;
    color: $secondary-bg-color;
    opacity: 0.3;
  }

  &-StatusBlock {
    display: flex;
    align-items: center;
  }

  &-StatusBadge {
    position: absolute;
    top: $standard-gap / 3 * 2;
    right: $standard-gap / 2;
  }

  &-RelativeBlock {
    position: relative;
  }

  &-PaymentStatusBadge {
    position: absolute;
    top: 0;
    right: -$standard-gap / 2;
  }

  &-AddressText {
    font-size: $small-font-size;
    font-weight: $main-font-weight;
  }
}

.TripDetails {
  padding-top: $standard-gap;

  &-Item {
    margin-bottom: $standard-gap;
  }

  &-Block {
    padding-top: $standard-gap / 4;
    padding-bottom: $standard-gap / 4;
    min-height: $standard-gap * 1.7;
  }

  &-BlockLabel {
    font-weight: $bold-font-weight;
  }

  &-SecondaryBlockLabel {
    font-weight: $bold-font-weight;
    font-size: $small-font-size;
  }

  &-SecondaryBlockText {
    font-size: $small-font-size;
  }

  &-Head {
    position: relative;
  }

  &-DepartureTime {
    border-radius: $control-secondary-border-radius;
    background-color: $elements-dark-bg-color;
    width: 150px;
    padding: $standard-gap / 4;
    color: $light-font-color;
    text-align: center;
  }

  &-RouteBlock {
    padding-top: $standard-gap / 2;
    padding-bottom: $standard-gap / 2;
    display: flex;
    justify-content: left;
    align-items: center;
    color: $elements-dark-bg-color;
  }

  &-OptionsBlock {
    color: $elements-dark-bg-color;
    gap: $standard-gap / 2;
    padding-top: $standard-gap / 2;
    padding-bottom: $standard-gap / 2;
    align-items: center;
  }

  &-CargoComment {
  }

  &-Option {
    align-items: center;
    font-size: $large-font-size;
    margin-bottom: 0;
  }

  &-OptionText {
    font-size: $small-font-size * 0.8;
    line-height: $small-font-size * 0.8;
  }

  &-AddOptionText {
    font-size: $main-font-size;
    padding-left: $standard-gap / 2;
  }
  &-TripOrderType {
  }

  &-PlacesAmount {
  }

  &-TripPrice {
  }

  &-TripOrderComment {
  }

  &-FindTripButton {
  }

  &-StatusBadge {
    position: absolute;
    top: $standard-gap / 5 * 2;
    right: 0;
  }

  &-TripStatusBadge {
    position: absolute;
    top: 0;
    right: 0;
  }

  &-PaymentStatusBadge {
    position: absolute;
    bottom: $standard-gap;
    right: 0;
  }

  &-SafeBadge {
    font-size: $main-font-size * 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: $standard-gap / 2;
  }
}

.IconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}


.InfoField {
  box-sizing: border-box;
  width: $control-width;
  height: $control-height;
  background-color: $elements-dark-bg-color;
  padding: 10px;
  font-weight: $main-font-weight;
  font-size: $main-font-size;
  border: none;
  border-radius: $control-secondary-border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $light-font-color;

  &_Contrast {
    background-color: $secondary-bg-color;
  }
}

.AlertText {
  color: red !important;
}

.PhoneLink {
  text-decoration: none;
}

.LeftPadding {
  padding-left: $standard-gap;
}

.LeftPaddingSmall {
  padding-left: $standard-gap / 2;
}

.LeftPaddingTiny {
  padding-left: $standard-gap / 4;
}

.ModalEvaluation {
  &-EvaluateUser {
    padding-top: 0 !important;
  }

  &-EvaluateDriver {
    padding-top: 0 !important;
  }
}

.BadgeInfoBlock {
  box-sizing: border-box;
  border-radius: $control-secondary-border-radius;
  border: solid 2px $elements-dark-bg-color;
  max-width: 290px;
  padding: $standard-gap / 2 $standard-gap / 4;
  font-weight: $bold-font-weight;
  font-size: $main-font-size * 1.2;

  &-Title {
    color: $elements-dark-bg-color,
  }
}

.RadioStatusesLabel {
  width: 100%;
  height: $standard-gap * 1.5;
  color: $main-font-color;
  font-weight: $bold-font-weight;
  border: 0;
  border-radius: 22px;
  text-align: center;
  line-height: $standard-gap * 1.5;
  display: flex;
  justify-content: left;
  align-items: center;

  &_Status {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid $elements-dark-bg-color;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_StatusInner {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $elements-dark-bg-color;
  }

  &_Text {
    padding-left: $standard-gap * 1.5;
  }
}

@media (max-width: 1320px) {
  .radio-directions-label__text {
    padding-left: 15px;
  }
}
@media (max-width: 1290px) {
  .radio-directions-label__text {
    padding-left: 10px;
  }
}
.radio-directions-label:hover {
  cursor: pointer;
}
.radio-directions-label_active {
  background-color: #FF0600;
  color: #252828;
}

.RadioStatusesInput {
  width: 1px !important;
  height: 1px !important;
  opacity: 0;
}

.DraggableReservationCard {
  &_Dragging {
    background-color: grey;
  }

  &_Hovered {
    background-color: red;
  }
}

.DispatcherDataInCard {
  font-size: $main-font-size;
}

.StarIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.InputWithAlert {
  position: relative;
}

.Visible {
  width: 200px;
  height: 100px;
  background-color: red;
  padding-top: 75px;
}

.Hidden {
  display: none;
}

.NewIndicator {
  width: 15px;
  height: 15px;
  background: radial-gradient(100% 100% at 0% 0%, $elements-light-bg-color 0%, $elements-dark-bg-color 100%);
  box-shadow: 0px 1px 5px $elements-dark-bg-color;
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }
}

.DataLabel {
  font-size: $main-font-size;
  font-weight: $bold-font-weight;

  @include onDesktop {
    font-size: $small-font-size-desktop;
    font-weight: $bold-font-weight;
  }

  &_Centered {
    text-align: center;
  }
}

.autocomplete-dropdown-container {
  position: absolute !important;
  z-index: 99;
}

//Menu for pages
.SectionNavMenu {
  height: calc(100vh - #{($header-height + $footer-height)});
  display: flex;
  flex-direction: column;

  @include onTablet {
    height: calc(100vh - #{($header-height-tablet + $footer-height-tablet)});
    overflow-y: auto;
  }

  @include onDesktop {
    height: calc(100vh - #{$header-height-desktop});
    //width: $content-max-width-desktop;
    //max-width: $content-max-width-desktop;
  }

  &-Item {
    min-height: $control-height;
    border-bottom: 1px solid $additional-bg-color;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    padding: $standard-gap / 4 $standard-gap;
    font-weight: $main-font-weight;
    flex-grow: 1;

    &:hover {
      cursor: pointer;
      background-color: $elements-dark-bg-color;
    }

    &:last-child {
      border-bottom: none;
    }

    @include onTablet {
      padding: $standard-gap / 2 $standard-gap;
      height: 100%;
    }

    @include onDesktop {
      height: 100%;
    }
  }

  &-Icon {
    width: 10%;

    @include onTablet {
      max-width: 40px;
    }

    @include onDesktop {
      max-width: 100px;
    }
  }

  &-Text {
    padding-left: 10px;
    flex-grow: 1;
  }

  &-Link {
    text-decoration: none;
    color: $main-font-color;

    &:hover {
      color: $main-font-color;
    }
  }

  &-NewIndicator {
    margin-right: $standard-gap;
  }
}

.MenuIcon {
  width: 100%;
  height: 100%;

  &_Clickable {
    &:hover {
      cursor: pointer;
    }
  }

  @include onDesktop {
    width: $control-height;
    height: $control-height;
  }
}

//Ref program text
.RefProgramDescription {
  margin-bottom: $standard-gap;
  font-size: $small-font-size;
  font-weight: $pale-font-weight;
  text-align: justify;

  @include onDesktop {
    padding: $standard-gap-desktop;
    font-size: $small-font-size-desktop;
  }

   p {
    min-height: 1px;
  }
}

//Standard page container
.Page {
  padding-top: $header-height;
  padding-bottom: $footer-height;

  @include onTablet {
    padding-top: $header-height-tablet;
    padding-bottom: $footer-height-tablet;
  }

  @include onDesktop {
    padding-top: $header-height-desktop;
    padding-bottom: 0;
  }
}

.Loader {
  //height: calc(100vh - #{($header-height + $footer-height + 120px)});
  height: 100%;
  min-height: 50vh;
  display: flex;
  gap: $standard-gap / 2;
  justify-content: center;
  align-items: center;

  @include onTablet {
    //height: calc(100vh - #{($header-height-tablet + $footer-height-tablet + 120px)});
  }

  @include onDesktop {
    //height: calc(100vh - #{$header-height-desktop + 120px});
  }
}

.LoaderSmall {
  max-height: $standard-gap;
}

.LoaderSmall .spinner-border {
  width: 20px;
  height: 20px;
}

.DropdownControl {
  background-color: $secondary-bg-color !important;
  border-color: $secondary-bg-color !important;
}

.UserLink {
  &:hover {
    cursor: pointer;
    color: $elements-dark-bg-color;
  }
}

.TitleWithButton {
  display: flex;
  gap: $standard-gap;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.SaveButton {
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  border-radius: $control-secondary-border-radius;
  border: 1px solid $additional-bg-color;
  color: $elements-dark-bg-color;
  background-color: $additional-bg-color;
}

.FullWidthButton {
  width: 100%;
}

.SwitchButton {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 24px;
  background-color: transparent;
  color: $elements-dark-bg-color;
  border: none;

  @include onTablet {
    width: 40px;
    height: 40px;
    font-size: 36px;
  }

  &_Disabled {
    color: $additional-bg-color;
  }
}

.dropdown-menu {
  width: 100% !important;
}

.Footer-Nav .NavMenu-Item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

//#region Table for admin
.DataTableForAdmin {
  margin: 0 auto;
  border-collapse: collapse;

  @include onTablet {
    width: $content-max-width;
  }

  @include onDesktop {
    width: $content-max-width-desktop;
  }

  &-Cell {
    padding: 5px 5px;
    height: $control-height + $standard-gap / 2;
  }

  &-Body {
  }

  &-CellWithButtons {
    display: flex;
    align-items: start;
    height: 100%;
  }

  &-ButtonsBlock {
    margin-right: 1px;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 1px;
  }

  &-EntityButton {
    width: $control-height * 0.6;
    height:$control-height * 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $control-secondary-border-radius;
    background-color: $elements-dark-bg-color;
    border: 1px solid $elements-dark-bg-color;
  }
}

thead tr:first-child {
  background-color: $elements-dark-bg-color;
  color: $light-font-color;
}

.HeaderCell {
  text-align: left;
  height: $control-height;
  min-width: 80px;

  &:first-child {
    border-top-left-radius: $control-secondary-border-radius;
  }

  &:last-child {
    border-top-right-radius: $control-secondary-border-radius;
  }

  @include onDesktop {
    min-width: 120px;
  }


  @for $size from 5 through 95 {
    &_W#{$size} {
      width: percentage($size / 100);
    }
  }
}

.TitleRow {
  background-color: $additional-bg-color;
  font-weight: $bold-font-weight;

  color: $elements-dark-bg-color;

  &:hover {
    background-color: $secondary-bg-color;
  }
}

.DataRow {
  color: $light-font-color;
  height: $control-height * 0.8;

  background-color: $secondary-bg-color;

  &_Last > td {
    &:first-child {
      border-bottom-left-radius: $control-secondary-border-radius;
    }

    &:last-child {
      border-bottom-right-radius: $control-secondary-border-radius;
    }
  }

  &:hover {
    background-color: $additional-bg-color;
  }
}

.rdrDefinedRangesWrapper {
  display: none !important;

  @include onDesktop {
    display: block !important;
  }
}
//#endregion

.editorClassName {
  background-color: #eee;
  min-height: 400px;
  border: 1px solid $additional-bg-color;
}

.LeftMarginTiny {
  margin-left: $standard-gap / 4;
}

.ToggleButton {
  border-radius: $control-secondary-border-radius;
  background-color: $secondary-bg-color;
  width: 150px;
  padding: $standard-gap / 4;
  color: $light-font-color;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.UsersTable {
  &-Cell {
    &_Centered {
      text-align: center;
    }
  }
}

.Pointer {
  &:hover {
    cursor: pointer;
  }
}

.DataListWithPagination {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.BlockLabel {
  font-weight: $bold-font-weight;
}

@for $id from 1 through 100 {
  #react-select-#{$id}-listbox {
    z-index: 99;
  }
}

.NotificationsLink {
  color: $light-font-color;

  &_HasNew {
    color: $elements-dark-bg-color;
  }

  &:hover {
    cursor: pointer;
  }
}
