@import './vars';
@import './mixins';

.FavouriteDriversList {
  padding-top: $standard-gap;
  display: grid;
  gap: $standard-gap;
  grid-template-columns: repeat(auto-fill, minmax($content-min-width, 1fr));
  width: $content-width;
  max-width: $content-max-width;

  @include onDesktop {
    padding-top: 0;
    width: $content-max-width-desktop;
    max-width: $content-max-width-desktop;
  }

  &-Title {
    display: none;

    @include onDesktop {
      display: block;
    }
  }
}
