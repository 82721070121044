@import './vars';
@import './mixins';

.MapContainer {
  @include onDesktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.CarOnMapLocationIq {
  height: calc(100vh - #{($header-height + $footer-height)});
  width: 100%;

  @include onDesktop {
    height: 800px;
    width: 1000px
  }
}
