@import './vars';
@import './mixins';

.Catalogs {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.Catalog {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &-DataList {
    flex-grow: 1;
  }

  &-TextArea {
    min-height: $control-height;
  }

  &-DataRow {
    background-color: $secondary-bg-color;
  }

  &-FirstRowCell {
    height: $control-height + $standard-gap / 2;
    padding-top: $standard-gap / 2;
  }
}
