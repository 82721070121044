@import './vars';
@import './mixins';

.Exchange {
  padding-top: $header-height;
  //min-width: $content-min-width;
  //width: $content-width;
  //max-width: $content-max-width;
  padding-bottom: $footer-height;
  min-height: 100vh;

  @include onDesktop {
    padding-bottom: 0;
    padding-top: $header-height-desktop;
  }

  &-TabControls {
    display: flex;
    justify-content: center;
    padding-top: $standard-gap;
  }
}
