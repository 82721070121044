@import './mixins';
@import './vars';

.WithdrawalsListForAdmin {
  &-Button {
    width: 0.5 * $control-height;
    height: 0.5 * $control-height;
    font-size: 18px;
    line-height: 18px;
  }
}
