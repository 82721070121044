@import './vars';
@import './mixins';

.ReferralLink {
  box-sizing: border-box;
  width: $control-width;
  min-height: $control-height;
  background-color: $secondary-bg-color;
  padding: $standard-gap / 2;
  font-weight: $main-font-weight;
  font-size: $main-font-size;
  border: none;
  border-radius: $control-secondary-border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $light-font-color;
}
