@mixin onTablet {
  @media (min-width: 744px) {
    @content;
  }
}

@mixin onDesktop {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin onLargeScreen {
  @media (min-width: 1920px) {
    @content;
  }
}
