@import './vars';
@import './mixins';

.RegistrationFormContainer {
  &-RegistrationForm {
    margin: 0 auto;
  }

  &-CameraContainer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
  }

  &-CameraCloseButton {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 100;
    background-color: transparent;
    border-radius: 50%;
    border: none;
    box-sizing: border-box;
    font-size: $standard-gap * 1.5;

    &:hover {
      cursor: pointer;
    }
  }
}

.RegistrationForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: $content-min-width;
  width: $content-width;
  max-width: $content-max-width;

  @include onDesktop {
    width: $content-max-width;
  }

  &-Item {
    position: relative;
    margin-bottom: $standard-gap;
    width: 100%;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
      width: $content-width;
      max-width: $content-max-width;
    }
  }

  &-PhoneItem {
    margin-top: $standard-gap / 2;
  }

  &-Label {
    display: block;
    margin-bottom: $standard-gap / 2;
    text-align: left;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop / 2;
    }
  }

  &-InputContainer {
    position: relative;
  }

  &-Input {
    padding-left: 44px;
  }

  &-InputIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }

  &-InputIconRight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }

  &-TextContainer {
    margin: 0;
    display: flex;
    justify-content: right;
  }

  &-Text {
    text-align: right;
    padding-right: 15px;
    text-decoration: none;
    color: $pale-font-color;
    font-weight: $pale-font-weight;

    @include onDesktop {
      font-size: $small-font-size-desktop;
    }
  }

  &-CheckboxWithText {
    padding-top: 10px;
  }

  &-RulesLink {
    margin-bottom: $standard-gap * 1.5;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop * 1.5;
    }
  }

  &-DateSelect {
    padding: 0;
    border: 1px solid $pale-font-color !important;
    border-radius: $control-border-radius !important;
    color: $pale-font-color !important;
    font-weight: $pale-font-weight;
    font-size: $small-font-size;
    min-width: 0.28 * $content-min-width;
    width: 0.28 * $content-width;
    max-width: 0.28 * $content-max-width;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }

    &_Error {
      border: 1px solid red !important;
    }
  }

  &-PlaceSelect {
    padding: 0;
    border: 1px solid $pale-font-color !important;
    border-radius: $control-border-radius !important;
    color: $pale-font-color !important;
    font-weight: $pale-font-weight;
    font-size: $small-font-size;
    min-width: 0.47 * $content-min-width;
    width: 0.47 * $content-width;
    max-width: 0.47 * $content-max-width;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  &-SideRegisterServices {
    margin-bottom: $standard-gap;
  }

  &-AddPhone {
    margin: 0 auto;
  }

  &-AddPhoneButton {
    margin-top: $standard-gap;
  }

  &-ChangePassword {
    margin: 0 auto;
  }
}

.InputsRowBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.react-tel-input {
  max-width: 100%;
  flex-grow: 1;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.css-1xc3v61-indicatorContainer {
  //padding: 0 !important;
}

.css-t3ipsp-control {
  box-shadow: none !important;
  border: 1px solid $main-font-color !important;
}
