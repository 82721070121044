@import './vars';
@import './mixins';

.CarOnMap {
  height: calc(100vh - #{($header-height + $footer-height)});
  width: 100%;

  @include onDesktop {
    height: 800px;
  }
}
