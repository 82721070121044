@import './vars';
@import './mixins';

.PlacesConstructor {
  display: flex;
  flex-direction: column;

  &-Item {
    margin-bottom: $standard-gap;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
    }
  }

  &-Input {
    width: 49%;
  }

  &-ContrastButton {
    background-color: $secondary-bg-color;
  }
}

//VEHICLE SCHEME
.VehicleBody {
  box-sizing: border-box;
  display: flex;
  width: max-content;
  //width: 90%;
  flex-direction: column;
  border: 1px solid black;
  padding: 10px;
  //grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
  //grid-template-rows: repeat(auto-fill, minmax(40px, 1fr));
}

.VehicleRow {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.VehicleCell {
  position: relative;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  margin: 2px;
  box-sizing: border-box;
  min-width: 40px;
  //width: 40px;
  height: 40px;
  font-size: 20px;

  &-Place {
    border: 1px solid black;
  }

  &-PlaceIcon {
    position: absolute;
    max-height: 16px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    line-height: 1;
    color: $main-font-color;
  }

  &-PlaceNumber {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    line-height: 1;
    color: $main-font-color;
  }
}

.EditPlaceButton {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $main-font-size;
  box-sizing: border-box;
  border-radius: $control-secondary-border-radius;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  //border: 2px solid $main-bg-color !important;
  border: none;
  background-color: $elements-dark-bg-color !important;
  color: $light-font-color !important;
  width: 20px !important;
  height: 20px !important;
  margin-bottom: 0 !important;

  &:hover {
    background-color: $secondary-bg-color !important;
    color: $light-font-color !important;
  }

  &_Disabled {
    background-color: $standard-border-color !important;

    &:hover {
      background-color: $standard-border-color !important;
      cursor: auto !important;
    }
  }

  @include onDesktop {
    width: 40px !important;
    height: 40px !important;
    margin-bottom: 0 !important;
  }
}

.EditPlaceCheckBox {
  margin: 0 !important;
  box-sizing: border-box;
  width: 20px !important;
  height: 20px !important;

  @include onDesktop {
    width: 40px !important;
    height: 40px !important;
  }
}

.EditPlaceCol {
  padding: 0 4px 0;
  color: $light-font-color;
}

.EditPlaceRow {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: center;
}

.PlaceNumber {
  max-width: 100px;
}

.fade.modal.show {
  //background-color: $additional-bg-color;
  //position: fixed;
  //min-width: $content-min-width;
  //border-radius: $control-border-radius;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%, -50%);
  //z-index: 99;
}
