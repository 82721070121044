@import './vars';
@import './mixins';

.ConfirmReservation {
  padding-top: $standard-gap;

  &-Item {
    margin-bottom: $standard-gap;
  }

  &-RadioInputContainer {
    //margin-right: $standard-gap;
  }

  &-RadioLabel {
    //padding-left: $standard-gap;
  }

  &-Select {
    padding: 0;
    border: 1px solid $pale-font-color !important;
    border-radius: $control-border-radius !important;
    color: $pale-font-color !important;
    font-weight: $pale-font-weight;
    font-size: $small-font-size;
    min-width: $standard-gap * 4;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  &-PlacesSelects {
    display: grid;
    gap: $standard-gap / 4;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }
}
