@import './vars';
@import './mixins';

.ShowProfileData {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: $content-min-width;
  width: $content-width;
  max-width: $content-max-width;

  @include onDesktop {
    width: $content-max-width;
  }

  &-Item {
    margin-bottom: $standard-gap;
    width: 100%;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
      width: $content-width;
      max-width: $content-max-width;
    }
  }

  &-PhoneItem {
    margin-top: $standard-gap / 2;
  }

  &-Label {
    display: block;
    margin-bottom: $standard-gap / 2;
    text-align: left;
    color: $elements-dark-bg-color;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop / 2;
    }
  }

  &-InputContainer {
    position: relative;
  }

  &-Input {
    padding-left: 44px;
    display: flex;
    align-items: center;
    background-color: $additional-bg-color;
    color: $elements-dark-bg-color;
    font-weight: $bold-font-weight;
  }

  &-Text {
    text-align: right;
    padding-right: 15px;
    text-decoration: none;
    color: $pale-font-color;
    font-weight: $pale-font-weight;

    @include onDesktop {
      font-size: $small-font-size-desktop;
    }
  }

  &-PhoneNumber {
    margin-top: $standard-gap / 2;
  }

  &-MessengersRow {
    padding-top: 0;
  }

  &-AddVehicle {
    margin: 0 auto;
  }
}
