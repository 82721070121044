@import './vars';
@import './mixins';

.FormInputAlertText {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  color: red;
  font-size: $small-font-size;
  font-weight: $bold-font-weight;
}
