@import './vars';
@import './mixins';

.CounterPartyCard {
  position: relative;
  padding: $standard-gap / 4;
  min-width: $content-min-width - $scroll-bar-width;
  //width: calc($content-width - $scroll-bar-width);
  max-width: $content-max-width - $scroll-bar-width;
  //background-color: $secondary-bg-color;
  //color: $light-font-color;
  border-radius: $control-secondary-border-radius;
  border: 2px solid $elements-dark-bg-color;

  @include onDesktop {
    min-width: $content-min-width;
    width: 100%;
    max-width: $content-max-width-desktop;
  }

  &_Archived {
    background-color: $additional-bg-color;
    border: 2px solid $pale-font-color;
    color: $pale-font-color;
  }

  &-Item {
    margin-bottom: $standard-gap / 2;
    align-items: center;
  }

  &-IconContainer {
    display: flex;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
  }

  &-PaymentStatusBadgeContainer {
    display: flex;
    justify-content: end;
  }

  &-PaymentStatusBadge {
    min-width: 130px;
    margin-right: -$standard-gap / 4;
  }
}
