@import './vars';
@import './mixins';

.CarsData {
  padding-top: $standard-gap;

  &-CarsList {
    margin: 0 auto;
    width: $content-width;
    max-width: $content-max-width;
  }

  &-Title {
    display: none;

    @include onDesktop {
      display: block;
    }
  }

  &-AddVehicle {
    padding-top: $standard-gap / 2;
    padding-bottom: $footer-height;
    margin: 0 auto;

    @include  onDesktop {
      padding-bottom: 0;
    }
  }

  &-DisplayVehicleDetails {
    margin: 0 auto;
  }
}

.CarsList {
  display: grid;
  gap: $standard-gap;
  grid-template-columns: repeat(auto-fill, minmax($content-min-width, 1fr));

  @include onDesktop {
    width: $content-max-width-desktop;
    max-width: $content-max-width-desktop;
  }

  &-AddVehicleButton {
    position: fixed;
    bottom: $footer-height + $standard-gap;
    min-width: $content-min-width;
    width: $content-width;
    max-width: $content-max-width;
    z-index: 99;
    left: 50%;
    transform: translateX(-50%);

    &:hover {
      left: 50%;
      transform: scale(1.01) translateX(-50%);
    }
  }
}
