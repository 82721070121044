@import './vars';
@import './mixins';

.Profile {
  min-height: calc(100vh - #{$footer-height});
  padding-top: $header-height;

  &-Title {
    display: none;
  }

  @include onTablet {
    min-height: 100vh;
    padding-top: $header-height-tablet;
    padding-bottom: $footer-height-tablet;
  }

  @include onDesktop {
    min-height: 100vh;
    padding-top: $header-height-desktop;
    padding-bottom: 0;
  }

  &-Nav {
    margin: 0 auto;
  }

  &-Content {
    position: relative;
  }

  &-BackArrow {
    position: absolute;
    left: 10%;
    top: -40px;
    width: 30px;
    height: 30px;
    background: url('../../public/img/profile-menu/back-arrow-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &-RulesAndConfidentialPolicy {
    margin: 0 auto;
  }

  &-AddToBalance {
    margin: 0 auto;
  }

  &-InviteDriver {
    padding-top: $standard-gap;
    margin: 0 auto;
  }

  &-WithdrawFromBalance {
    margin: 0 auto;
  }
}

.Profile-RulesAndConfidentialPolicy.RulesAndConfidentialityPolicy {
  padding-top: 0;
}

.Profile-RulesAndConfidentialPolicy .RegularButton {
  display: none;
}
