@import './vars';
@import './mixins';

.AddTrip {
  &-Item {
    margin-bottom: $standard-gap;
    width: 100%;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
      width: $content-width;
      max-width: $content-max-width;
    }
  }

  &-Label {
    display: block;
    margin-bottom: $standard-gap / 2;
    text-align: left;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop / 2;
    }
  }

  &-InputContainer {
    position: relative;
  }

  &-Input {
    padding-left: 44px;
  }

  &-InputIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }

  &-InputIconRight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }

  &-TextContainer {
    margin: 0;
    display: flex;
    justify-content: right;
  }

  &-Text {
    text-align: right;
    padding-right: 15px;
    text-decoration: none;
    color: $pale-font-color;
    font-weight: $pale-font-weight;

    @include onDesktop {
      font-size: $small-font-size-desktop;
    }
  }

  &-CheckboxWithText {
    padding-top: 10px;
  }

  &-Select {
    padding: 0;
    border: 1px solid $pale-font-color !important;
    border-radius: $control-border-radius !important;
    color: $pale-font-color !important;
    font-weight: $pale-font-weight;
    font-size: $small-font-size;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

.AddTripForm {
  &-Item {
    margin-bottom: $standard-gap;
    width: 100%;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
      width: $content-width;
      max-width: $content-max-width;
    }
  }

  &-Label {
    display: block;
    margin-bottom: $standard-gap / 2;
    text-align: left;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop / 2;
    }
  }

  &-InputContainer {
    position: relative;
  }

  &-Input {
    padding-left: 44px;
  }

  &-InputIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }

  &-InputIconRight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }

  &-TextContainer {
    margin: 0;
    display: flex;
    justify-content: right;
  }

  &-Text {
    text-align: right;
    padding-right: 15px;
    text-decoration: none;
    color: $pale-font-color;
    font-weight: $pale-font-weight;

    @include onDesktop {
      font-size: $small-font-size-desktop;
    }
  }

  &-CheckboxWithText {
    padding-top: 10px;
  }

  &-Select {
    padding: 0;
    border: 1px solid $pale-font-color !important;
    border-radius: $control-border-radius !important;
    color: $pale-font-color !important;
    font-weight: $pale-font-weight;
    font-size: $small-font-size;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  &-PlacesNumber {
    align-items: center;
  }

  &-PlaceCost {
    align-items: center;
  }

  &-PlacesInTripEditing {
    margin: 0 auto;
  }
}

