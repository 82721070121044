@import './vars';
@import './mixins';

.RegistrationPage {
  padding-top: $header-height;

  @include onTablet {
    padding-top: $header-height-tablet;
  }

  @include onDesktop {
    padding-top: $header-height-desktop;
  }

  &-RegistrationForm {
    margin: 0 auto;
  }

  &-AddVehicle {
    margin: 0 auto;
  }
}
