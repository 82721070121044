@import './vars';
@import './mixins';

.FavouriteDriverCard {
  position: relative;
  padding: $standard-gap / 2;
  min-width: $content-min-width;
  //width: $content-width;
  max-width: $content-max-width;
  //background-color: $secondary-bg-color;
  //color: $light-font-color;
  border-radius: $control-secondary-border-radius;
  border: 2px solid $elements-dark-bg-color;

  &:hover {
    cursor: pointer;
  }

  @include onDesktop {
    min-width: $content-min-width;
  }

  &-Item {
    margin-bottom: $standard-gap / 2;
  }

  &-NameBlock {
    align-items: center;
  }

  &-IconContainer {
    display: flex;
    align-items: center;
  }

  &-Button {
    transition: 0.2s;
    &:hover {
      cursor: pointer;
    }

    &:active {
      transform: scale(0.99);
    }
  }
}
