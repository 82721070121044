@import './vars';
@import './mixins';

.NotificationsListContainer {
  max-height: calc(100vh - #{($header-height + $footer-height + $pagination-height)});
  flex-grow: 1;
  overflow-y: auto;

  @include onTablet {
    max-height: calc(100vh - #{($header-height-tablet + $footer-height-tablet + $pagination-height)});
  }

  @include onDesktop {
    //width: $content-max-width-desktop + $scroll-bar-width;
    max-height:  calc(100vh - #{($header-height-desktop + $pagination-height-desktop)});
  }
}

.NotificationsList {
  //width: calc(100vw - $scroll-bar-width);
  height: 100%;
  display: flex;
  flex-direction: column;

  &-Item {
    position: relative;
    padding: $standard-gap / 2 $standard-gap;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $additional-bg-color;
    flex-grow: 1;
    max-height: 60px;

    &:hover {
      cursor: pointer;
    }
  }

  &-NewIndicator {
    position: absolute;
    top: 50%;
    right: $standard-gap / 2;
    transform: translateY(-50%);
  }
}

.NoNotifications {
  height: 100%;
  background: url("../../public/img/no-trips.webp");
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  &-NoEntriesText {
    text-align: center;
    color: $pale-font-color;
    font-weight: $main-font-weight;
    position: absolute;
    bottom: $footer-height * 3;
    min-width: $content-min-width;
    width: $content-width;
    max-width: $content-max-width;

    @include onDesktop {
      left: 50%;
      transform: translateX(-50%);
      bottom: 10vh;
    }
  }
}
