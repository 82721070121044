@import './vars';
@import './mixins';

.AppSettings {
  &-Item {
    @include onDesktop {
      width: 50%;
    }
  }
}
