@import './vars';
@import './mixins';

.MessengersRow {
  padding-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: left;
  align-items: center;
}

.MessengerIcon {
  display: flex;
  align-items: center;
  font-size: 20px;
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    transform: scale(1.2);
  }

  &_Viber {
    color: #59267c !important;
  }

  &_WhatsApp {
    //stroke: #0d8d2d;
    color: #25d366 !important;
  }

  &_Telegram {
    //stroke: #0E76A8;
    color: #0088cc !important;
  }

  &_Huge {
    font-size: 80px;
  }
}

.MessengerCheckBox {
  margin-bottom: 0 !important;
  width: 15px !important;
  height: 15px !important;

  &:hover {
    cursor: pointer;
  }
}
