@import './vars';
@import './mixins';

.PersonData {
  padding-top: $standard-gap;
  padding-bottom: $footer-height;

  @include onTablet {
    padding-bottom: $footer-height-tablet;
  }

  @include onDesktop {
    padding-bottom: 0;
  }

  &-ShowProfileData {
    margin: 0 auto;
  }
}
