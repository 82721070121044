@import './vars';
@import './mixins';

.CreateTextPage {
  &-ImagesList {
    display: grid;
    gap: $standard-gap;
    grid-template-columns: repeat(auto-fill, 80px);
    grid-template-rows: repeat(auto-fill, 80px);
  }

  &-ImageContainer {
    position: relative;
  }

  &-Image {
    width: 80px;
    height: 80px;
  }

  &-AddImage {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 76px;
    color: $elements-dark-bg-color;

    &:hover {
      cursor: pointer;
    }
  }

  &-DeleteImage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    font-size: 10px;
    position: absolute;
    bottom: 5px;
    right: 5px;
    border: 1px solid red;
    border-radius: $control-secondary-border-radius;
    color: red;

    &:hover {
      cursor: pointer;
      background-color: red;
      color: $light-font-color;
    }
  }
}
