@import './vars';
@import './mixins';

.TripReservation {
  padding-top: $standard-gap;

  @include onDesktop {
    padding-top: 0;
  }

  &-RecommendationText {
    text-align: center;
    color: $elements-dark-bg-color;
    font-weight: $bold-font-weight;
  }

  &-Item {
    margin-bottom: $standard-gap;
  }
}
