@import './vars';
@import './mixins';

.DictionariesEditing {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &-SelectLanguageButton {
    width: $control-width;
    height: $control-height;
    font-weight: $main-font-weight;
    font-size: $main-font-size;
    border-radius: $control-border-radius;
    background-color: $secondary-bg-color;
    width: 100%;
    border: none;
    color: $light-font-color;

    &:hover {
      background-color: $secondary-bg-color !important;
    }

    &:active {
      background-color: $secondary-bg-color !important;
    }
  }

  &-DataList {
    flex-grow: 1;
  }
}

.DictionariesEditing-SelectLanguageButtonContainer .dropdown-menu {
  max-height: 300px !important;
  overflow-x: hidden;
  overflow-y: auto !important;
}

.dropdown-item {
  //width: 20% !important;
  margin-right: 0 !important;
}

.LanguagesTable {
  margin: 0 auto;
  border-collapse: collapse;

  @include onTablet {
    width: $content-max-width;
  }

  @include onDesktop {
    width: $content-max-width-desktop;
  }

  &-Cell {
    padding: 5px 10px;
  }

  &-Body {
  }
}
