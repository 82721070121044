@import './vars';
@import './mixins';

.UsersList {
  &-SearchBlock {
    margin-bottom: $standard-gap / 4;

    @include onDesktop {
      margin-bottom: 0;
      width: 48%;
    }
  }

  &-FiltersBlock {
    @include onDesktop {
      width: 50%;
    }
  }
}
