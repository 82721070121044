@import './vars';
@import './mixins';

.AddPhone {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: $content-min-width;
  width: $content-width;
  max-width: $content-max-width;

  @include onDesktop {
    min-height: 100vh;
    width: $content-max-width;
    padding-top: $header-height-desktop;
  }

  &-Item {
    margin-bottom: $standard-gap;
    width: 100%;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
      width: $content-width;
      max-width: $content-max-width;
    }
  }

  &-Label {
    display: block;
    margin-bottom: $standard-gap / 2;
    text-align: left;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop / 2;
    }
  }
}
