@import './vars';
@import './mixins';

.Notifications {
  height: calc(100vh - #{($header-height + $footer-height)});

  @include onTablet {
    height: calc(100vh - #{($header-height-tablet + $footer-height-tablet)});
  }

  @include onDesktop {
    height:  calc(100vh - #{$header-height-desktop});
  }
}
