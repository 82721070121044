@import './vars';
@import './mixins';

.EvaluateUser {
  margin: 0 auto; // replace later to upper class
  padding-top: $header-height + $standard-gap;
  min-width: $content-min-width;
  width: $content-width;
  max-width: $content-max-width;

  @include onDesktop {
    padding-top: $header-height-desktop + $standard-gap;
    width: 100%;
  }

  &-Item {
    margin-bottom: $standard-gap;
    position: relative;
  }

  &-EvaluationContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #dddddd, $additional-bg-color);
    border-radius: $control-border-radius;
    width: 100%;
    min-height: $standard-gap * 6;
    font-weight: $bold-font-weight;
  }

  &-Stars {
    width: max-content;
  }

  &-StarsTitle {
    margin-bottom: $standard-gap / 2;
  }

  &-Star {
    margin-right: $standard-gap / 2;

    &:hover {
      cursor: pointer;
    }
  }

  &-Comment {
    padding: $standard-gap / 2;
    min-height: $standard-gap * 5;
    background-color: $additional-bg-color;
    border-radius: $control-border-radius / 2;
    border: none;
    resize: none;
    width: 100%;
    color: $elements-light-bg-color;

    &::placeholder {
      color: $elements-light-bg-color;
    }
  }
}
