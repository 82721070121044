.FormControl {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-radius: 32px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  font-size: 14px;
}
.FormControl:focus {
  outline: none;
  border: 2px solid #000;
}
.FormControl_Error {
  border: 1px solid red;
}
@media (min-width: 1280px) {
  .FormControl {
    font-size: 16px;
  }
}

.RegularButton {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding: 10px;
  background-color: #4d5dfa;
  font-weight: 500;
  font-size: 16px;
  border: none;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: 0.2s;
}
.RegularButton:hover {
  cursor: pointer !important;
  transform: scale(1.01);
}
.RegularButton:active {
  transform: scale(0.99);
}
.RegularButton_Contrast {
  background-color: #000;
  color: #fff;
}
.RegularButton_Pale {
  background-color: rgba(77, 84, 250, 0.6666666667);
}
.RegularButton_Centered {
  max-width: 490px;
  margin: 0 auto;
}
.RegularTitle {
  font-weight: 700;
  font-size: 28px;
  line-height: 1.4em;
  text-align: center;
  margin-bottom: 10px;
}
@media (min-width: 1280px) {
  .RegularTitle {
    font-size: 36px;
  }
}
.RegularTitle_Small {
  font-size: 16px;
}
@media (min-width: 1280px) {
  .RegularTitle_Small {
    font-size: 24px;
  }
}
.RegularTitle_Tiny {
  font-size: 14px;
}
@media (min-width: 1280px) {
  .RegularTitle_Tiny {
    font-size: 16px;
  }
}
.RegularTitle_Left {
  text-align: left;
}

.Logo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.Logo:hover {
  cursor: pointer;
}
@media (min-width: 744px) {
  .Logo {
    width: 60px;
    height: 60px;
  }
}
@media (min-width: 1280px) {
  .Logo {
    width: 80px;
    height: 80px;
  }
}

.NavMenu {
  display: none;
}
@media (min-width: 1280px) {
  .NavMenu {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.NavMenu-Item {
  padding: 5px;
  text-decoration: none;
  color: #fff;
}
.NavMenu-Item_Active {
  color: #4d5dfa;
}
.NavMenu-IconForAdmin {
  font-size: 24px;
}

.Notification {
  display: flex;
  align-items: center;
  border-radius: 32px;
  background-color: #cdd3dc;
}

.AlertNotification {
  color: #4d5dfa;
  font-size: 14px;
}

.RadioFieldSet {
  padding: 0;
  border: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
}
.RadioFieldSet-Input {
  width: 1px !important;
  height: 1px !important;
  opacity: 0;
}
.RadioFieldSet-Label {
  width: 45%;
  height: 100%;
  background-color: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.7);
  border-radius: 32px;
  text-align: center;
  line-height: 50px;
}
.RadioFieldSet-Label:hover {
  cursor: pointer;
}
.RadioFieldSet-Label_Disabled {
  background-color: #cacaca;
}
.RadioFieldSet-Label_Active {
  border: none;
  background-color: #4d5dfa;
  color: #fff;
}

.Avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: #cdd3dc;
  object-fit: cover;
  object-position: center;
}

.FieldLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background-color: #cdd3dc;
}
.FieldLabel:hover {
  cursor: pointer;
}

.Input-Photo {
  display: none;
}

.Phone-Container {
  width: 100%;
  margin: 0 auto;
}

.SeparationBlock {
  width: 100vw;
  height: 1px;
  background-color: #cdd3dc;
}
.SeparationBlock_Limited {
  width: 100%;
}
.SeparationBlock_ConsideringScroll {
  width: calc(100vw - 8px);
}

.PhoneRow {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 100%;
}

.PhoneAddRemoveButtons {
  position: absolute;
  top: 50%;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  gap: 2px;
  transform: translateY(-50%);
}

.PhoneAddButton {
  box-sizing: border-box;
  display: inline-block;
  border: 2px solid #000;
  border-radius: 4px;
  font-size: 20px;
  line-height: 20px;
  font-width: 800;
  position: relative;
  z-index: 2;
  color: #000;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  width: 50px;
  flex-grow: 1 !important;
}
.PhoneAddButton:hover {
  background-color: #000 !important;
  color: #fff !important;
}

.EditButton {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 2px solid #000;
  color: #fff;
  width: 20px;
  height: 20px;
  background-color: #fff;
  padding: 0;
}
.EditButton:hover {
  background-color: #4d5dfa;
}

.EditIconInTable {
  width: 14px;
  height: 14px;
}

.DelButton {
  box-sizing: border-box;
  display: inline-block;
  border-radius: 4px;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  border: 2px solid red;
  color: red;
  width: 20px;
  height: 20px;
  background-color: #fff;
  padding: 0;
  background-color: #fff;
}
.DelButton:hover {
  background-color: red;
  color: #fff;
}

.ShowButton {
  box-sizing: border-box;
  display: inline-block;
  border-radius: 4px;
  font-size: 10px;
  line-height: 10px;
  position: relative;
  z-index: 2;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  border: 2px solid #4d5dfa;
  color: #4d5dfa;
  width: 20px;
  height: 20px;
  background-color: #fff;
  padding: 0;
}
.ShowButton:hover {
  background-color: #4d5dfa;
  color: #fff;
}

.ConfirmButton {
  box-sizing: border-box;
  display: inline-block;
  border-radius: 4px;
  font-size: 10px;
  line-height: 10px;
  position: relative;
  z-index: 2;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  border: 2px solid green;
  color: green;
  width: 20px;
  height: 20px;
  background-color: #fff;
  padding: 0;
}
.ConfirmButton:hover {
  background-color: green;
  color: #fff;
}

.react-tel-input {
  max-width: 100%;
  flex-grow: 1;
}

.form-control {
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.7) !important;
  border-radius: 32px !important;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  font-size: 14px;
}
.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 2px solid #000 !important;
}
@media (min-width: 1280px) {
  .form-control {
    font-size: 16px;
  }
}

.PhoneRow_Error .form-control {
  border: 1px solid red !important;
}

.react-tel-input {
  width: 100%;
}

.react-tel-input .form-control {
  flex-grow: 1;
  width: 100% !important;
  max-width: 490px;
  min-height: 50px !important;
}

.flex-row {
  justify-content: center;
}

.nav-item {
  min-width: 180px;
  text-align: center;
  background-color: #cdd3dc;
  border-radius: 4px;
}

.nav-link {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.nav-pills {
  border-radius: 4px;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #000 !important;
}

.RulesLinkContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.RulesLinkContainer-CheckBox {
  width: 20px;
  height: 20px;
}
.RulesLinkContainer-CheckBox:hover {
  cursor: pointer;
}
.RulesLinkContainer-Text {
  text-align: left;
  color: #000;
  font-size: 11.2px;
  line-height: 1.2em;
}
.RulesLinkContainer-Link:hover {
  color: #4d5dfa;
}
@media (min-width: 1280px) {
  .RulesLinkContainer-Link {
    text-decoration: none;
  }
}

.CheckboxWithText {
  display: flex;
  justify-content: left;
  align-items: center;
}
.CheckboxWithText-CheckBox {
  width: 15px;
  height: 15px;
}
.CheckboxWithText-Text {
  padding-left: 10px;
  text-align: left;
  color: #000;
  font-size: 11.2px !important;
  line-height: 1.2em;
}

.FieldsContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.FlexBlock {
  display: flex;
  justify-content: space-between;
}
.FlexBlock_Columns {
  flex-direction: column;
}
@media (min-width: 1280px) {
  .FlexBlock_Columns {
    flex-direction: row;
  }
}
@media (min-width: 1280px) {
  .FlexBlock_Columns_OnDesktopToo {
    flex-direction: column;
  }
}
.FlexBlock_JustifyLeft {
  justify-content: left;
}
.FlexBlock_SpaceAround {
  justify-content: space-around;
}
.FlexBlock_JustifyCenter {
  justify-content: center;
}
.FlexBlock_AlignCenter {
  align-items: center;
}
.FlexBlock-HorizontalItem {
  width: 50%;
}
.FlexBlock-HorizontalItem_W5 {
  width: 5%;
}
.FlexBlock-HorizontalItem_W6 {
  width: 6%;
}
.FlexBlock-HorizontalItem_W7 {
  width: 7%;
}
.FlexBlock-HorizontalItem_W8 {
  width: 8%;
}
.FlexBlock-HorizontalItem_W9 {
  width: 9%;
}
.FlexBlock-HorizontalItem_W10 {
  width: 10%;
}
.FlexBlock-HorizontalItem_W11 {
  width: 11%;
}
.FlexBlock-HorizontalItem_W12 {
  width: 12%;
}
.FlexBlock-HorizontalItem_W13 {
  width: 13%;
}
.FlexBlock-HorizontalItem_W14 {
  width: 14%;
}
.FlexBlock-HorizontalItem_W15 {
  width: 15%;
}
.FlexBlock-HorizontalItem_W16 {
  width: 16%;
}
.FlexBlock-HorizontalItem_W17 {
  width: 17%;
}
.FlexBlock-HorizontalItem_W18 {
  width: 18%;
}
.FlexBlock-HorizontalItem_W19 {
  width: 19%;
}
.FlexBlock-HorizontalItem_W20 {
  width: 20%;
}
.FlexBlock-HorizontalItem_W21 {
  width: 21%;
}
.FlexBlock-HorizontalItem_W22 {
  width: 22%;
}
.FlexBlock-HorizontalItem_W23 {
  width: 23%;
}
.FlexBlock-HorizontalItem_W24 {
  width: 24%;
}
.FlexBlock-HorizontalItem_W25 {
  width: 25%;
}
.FlexBlock-HorizontalItem_W26 {
  width: 26%;
}
.FlexBlock-HorizontalItem_W27 {
  width: 27%;
}
.FlexBlock-HorizontalItem_W28 {
  width: 28%;
}
.FlexBlock-HorizontalItem_W29 {
  width: 29%;
}
.FlexBlock-HorizontalItem_W30 {
  width: 30%;
}
.FlexBlock-HorizontalItem_W31 {
  width: 31%;
}
.FlexBlock-HorizontalItem_W32 {
  width: 32%;
}
.FlexBlock-HorizontalItem_W33 {
  width: 33%;
}
.FlexBlock-HorizontalItem_W34 {
  width: 34%;
}
.FlexBlock-HorizontalItem_W35 {
  width: 35%;
}
.FlexBlock-HorizontalItem_W36 {
  width: 36%;
}
.FlexBlock-HorizontalItem_W37 {
  width: 37%;
}
.FlexBlock-HorizontalItem_W38 {
  width: 38%;
}
.FlexBlock-HorizontalItem_W39 {
  width: 39%;
}
.FlexBlock-HorizontalItem_W40 {
  width: 40%;
}
.FlexBlock-HorizontalItem_W41 {
  width: 41%;
}
.FlexBlock-HorizontalItem_W42 {
  width: 42%;
}
.FlexBlock-HorizontalItem_W43 {
  width: 43%;
}
.FlexBlock-HorizontalItem_W44 {
  width: 44%;
}
.FlexBlock-HorizontalItem_W45 {
  width: 45%;
}
.FlexBlock-HorizontalItem_W46 {
  width: 46%;
}
.FlexBlock-HorizontalItem_W47 {
  width: 47%;
}
.FlexBlock-HorizontalItem_W48 {
  width: 48%;
}
.FlexBlock-HorizontalItem_W49 {
  width: 49%;
}
.FlexBlock-HorizontalItem_W50 {
  width: 50%;
}
.FlexBlock-HorizontalItem_W51 {
  width: 51%;
}
.FlexBlock-HorizontalItem_W52 {
  width: 52%;
}
.FlexBlock-HorizontalItem_W53 {
  width: 53%;
}
.FlexBlock-HorizontalItem_W54 {
  width: 54%;
}
.FlexBlock-HorizontalItem_W55 {
  width: 55%;
}
.FlexBlock-HorizontalItem_W56 {
  width: 56%;
}
.FlexBlock-HorizontalItem_W57 {
  width: 57%;
}
.FlexBlock-HorizontalItem_W58 {
  width: 58%;
}
.FlexBlock-HorizontalItem_W59 {
  width: 59%;
}
.FlexBlock-HorizontalItem_W60 {
  width: 60%;
}
.FlexBlock-HorizontalItem_W61 {
  width: 61%;
}
.FlexBlock-HorizontalItem_W62 {
  width: 62%;
}
.FlexBlock-HorizontalItem_W63 {
  width: 63%;
}
.FlexBlock-HorizontalItem_W64 {
  width: 64%;
}
.FlexBlock-HorizontalItem_W65 {
  width: 65%;
}
.FlexBlock-HorizontalItem_W66 {
  width: 66%;
}
.FlexBlock-HorizontalItem_W67 {
  width: 67%;
}
.FlexBlock-HorizontalItem_W68 {
  width: 68%;
}
.FlexBlock-HorizontalItem_W69 {
  width: 69%;
}
.FlexBlock-HorizontalItem_W70 {
  width: 70%;
}
.FlexBlock-HorizontalItem_W71 {
  width: 71%;
}
.FlexBlock-HorizontalItem_W72 {
  width: 72%;
}
.FlexBlock-HorizontalItem_W73 {
  width: 73%;
}
.FlexBlock-HorizontalItem_W74 {
  width: 74%;
}
.FlexBlock-HorizontalItem_W75 {
  width: 75%;
}
.FlexBlock-HorizontalItem_W76 {
  width: 76%;
}
.FlexBlock-HorizontalItem_W77 {
  width: 77%;
}
.FlexBlock-HorizontalItem_W78 {
  width: 78%;
}
.FlexBlock-HorizontalItem_W79 {
  width: 79%;
}
.FlexBlock-HorizontalItem_W80 {
  width: 80%;
}
.FlexBlock-HorizontalItem_W81 {
  width: 81%;
}
.FlexBlock-HorizontalItem_W82 {
  width: 82%;
}
.FlexBlock-HorizontalItem_W83 {
  width: 83%;
}
.FlexBlock-HorizontalItem_W84 {
  width: 84%;
}
.FlexBlock-HorizontalItem_W85 {
  width: 85%;
}
.FlexBlock-HorizontalItem_W86 {
  width: 86%;
}
.FlexBlock-HorizontalItem_W87 {
  width: 87%;
}
.FlexBlock-HorizontalItem_W88 {
  width: 88%;
}
.FlexBlock-HorizontalItem_W89 {
  width: 89%;
}
.FlexBlock-HorizontalItem_W90 {
  width: 90%;
}
.FlexBlock-HorizontalItem_W91 {
  width: 91%;
}
.FlexBlock-HorizontalItem_W92 {
  width: 92%;
}
.FlexBlock-HorizontalItem_W93 {
  width: 93%;
}
.FlexBlock-HorizontalItem_W94 {
  width: 94%;
}
.FlexBlock-HorizontalItem_W95 {
  width: 95%;
}
.FlexBlock-HorizontalItem_W96 {
  width: 96%;
}
.FlexBlock-HorizontalItem_W97 {
  width: 97%;
}
.FlexBlock-HorizontalItem_W98 {
  width: 98%;
}
.FlexBlock-HorizontalItem_W99 {
  width: 99%;
}
.FlexBlock-HorizontalItem_W100 {
  width: 100%;
}

.OptionCheckBox {
  margin-bottom: 0;
  width: 20px;
  height: 20px;
}
.OptionCheckBox:hover {
  cursor: pointer;
}

.OptionText {
  padding-left: 10px;
}
.OptionText:hover {
  cursor: pointer;
}
.OptionText_Bold {
  font-weight: 600;
}

.TextArea {
  min-height: 100px;
}

.ColoredText {
  color: #4d5dfa;
}

.css-1xc3v61-indicatorContainer:hover {
  cursor: pointer;
}

.TabControls {
  padding-top: 10px;
}
@media (min-width: 1280px) {
  .TabControls {
    padding-top: 0;
  }
}
.TabControls-AddButtonContainer {
  display: none;
}
@media (min-width: 1280px) {
  .TabControls-AddButtonContainer {
    display: initial;
  }
}
.TabControls-AddButtonContainer_Right {
  padding-left: 5px;
}
.TabControls-AddButtonContainer_Left {
  padding-right: 5px;
}
.TabControls-AddButton {
  display: none;
}
@media (min-width: 1280px) {
  .TabControls-AddButton {
    display: initial;
    max-height: 40px;
    padding: 5px;
    min-width: 180px;
  }
}

.TabContent {
  padding-top: 10px;
}

.PaddingBlock {
  padding-top: 20px;
}
.PaddingBlock_Half {
  padding-top: 10px;
}

.ListContainer {
  flex-grow: 1;
  height: 70vh;
  max-height: 70vh;
  overflow-y: auto;
  box-sizing: border-box;
}
@media (min-width: 1280px) {
  .ListContainer {
    width: 1200px;
    height: 65vh;
    max-height: 65vh;
  }
}
.ListContainer_Extended {
  height: 75vh;
  max-height: 75vh;
}
.ListContainer_Reduced {
  height: 62vh;
  max-height: 62vh;
}
@media (min-width: 1280px) {
  .ListContainer_Reduced {
    height: 60vh;
    max-height: 60vh;
  }
}
.ListContainer_SharplyReduced {
  height: 52vh;
  max-height: 52vh;
}
@media (min-width: 1280px) {
  .ListContainer_SharplyReduced {
    height: 55vh;
    max-height: 55vh;
  }
}
.ListContainer_ExtremelyReduced {
  max-height: 38vh;
}
@media (min-width: 1280px) {
  .ListContainer_ExtremelyReduced {
    max-height: 35vh;
  }
}

.RouteBlock {
  color: #4d5dfa;
}

.DesktopTitle {
  display: none;
}
@media (min-width: 1280px) {
  .DesktopTitle {
    display: block;
    text-align: center;
  }
}

.RadioItem {
  padding-bottom: 10px;
}
.RadioItem :hover {
  cursor: pointer;
}

.CardsList {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
}
@media (min-width: 1280px) {
  .CardsList {
    max-width: 1200px;
  }
}
.CardsList-AddButton {
  position: fixed;
  bottom: 155px;
  min-width: 330px;
  width: 88vw;
  max-width: 490px;
  z-index: 99;
}
@media (min-width: 744px) {
  .CardsList-AddButton {
    bottom: 20vh;
  }
}
@media (min-width: 1280px) {
  .CardsList-AddButton {
    display: none;
  }
}

.TripCard {
  position: relative;
  box-sizing: border-box;
  min-width: 330px;
  max-width: 490px;
  border-radius: 4px;
  border: 2px solid #4d5dfa;
  height: 100%;
}
.TripCard:hover {
  cursor: pointer;
}
@media (min-width: 1280px) {
  .TripCard {
    min-width: 330px;
  }
}
.TripCard-HeadBlock {
  padding: 5px 10px;
  text-align: center;
  font-weight: 600;
}
.TripCard-DateBlock {
  min-width: 145px;
  background-color: #000;
  border-bottom-right-radius: 4px;
  color: #fff;
}
.TripCard-PriceBlock {
  color: #4d5dfa;
}
.TripCard_Cancelled {
  border: 2px solid #000;
  background-color: #cdd3dc;
}
.TripCard-Item {
  margin-bottom: 5px;
}
.TripCard-DateText {
  font-size: 16px;
}
.TripCard-MainSection {
  position: relative;
  padding: 5px 10px;
  font-weight: 600;
  font-size: 17.6px;
}
.TripCard-Block {
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 40px;
}
.TripCard-RouteBlock {
  display: flex;
  justify-content: left;
  align-items: center;
  vertical-align: center;
  color: #4d5dfa;
  max-width: 72%;
}
.TripCard-OptionsBlock {
  color: #4d5dfa;
  gap: 10px;
  font-size: 28px;
}
.TripCard-TripTypeIcon {
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: 84px;
  color: #4d5dfa;
}
.TripCard-BadgeNew {
  position: absolute;
  top: 0;
  right: 0;
  border-bottom-left-radius: 4px;
  min-width: 10%;
  background-color: #4d5dfa;
  color: #fff;
  padding: 5px;
}
.TripCard-EditButton {
  position: absolute;
  top: 10px;
  right: 20px;
}
.TripCard-EditButton:hover {
  cursor: pointer;
}
.TripCard-CancelledTripIcon {
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: 84px;
  color: #000;
  opacity: 0.3;
}
.TripCard-StatusBlock {
  display: flex;
  align-items: center;
}
.TripCard-StatusBadge {
  position: absolute;
  top: 13.3333333333px;
  right: 10px;
}
.TripCard-RelativeBlock {
  position: relative;
}
.TripCard-PaymentStatusBadge {
  position: absolute;
  top: 0;
  right: -10px;
}
.TripCard-AddressText {
  font-size: 14px;
  font-weight: 500;
}

.TripDetails {
  padding-top: 20px;
}
.TripDetails-Item {
  margin-bottom: 20px;
}
.TripDetails-Block {
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 34px;
}
.TripDetails-BlockLabel {
  font-weight: 600;
}
.TripDetails-SecondaryBlockLabel {
  font-weight: 600;
  font-size: 14px;
}
.TripDetails-SecondaryBlockText {
  font-size: 14px;
}
.TripDetails-Head {
  position: relative;
}
.TripDetails-DepartureTime {
  border-radius: 4px;
  background-color: #4d5dfa;
  width: 150px;
  padding: 5px;
  color: #fff;
  text-align: center;
}
.TripDetails-RouteBlock {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  color: #4d5dfa;
}
.TripDetails-OptionsBlock {
  color: #4d5dfa;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}
.TripDetails-Option {
  align-items: center;
  font-size: 28px;
  margin-bottom: 0;
}
.TripDetails-OptionText {
  font-size: 11.2px;
  line-height: 11.2px;
}
.TripDetails-AddOptionText {
  font-size: 16px;
  padding-left: 10px;
}
.TripDetails-StatusBadge {
  position: absolute;
  top: 8px;
  right: 0;
}
.TripDetails-TripStatusBadge {
  position: absolute;
  top: 0;
  right: 0;
}
.TripDetails-PaymentStatusBadge {
  position: absolute;
  bottom: 20px;
  right: 0;
}
.TripDetails-SafeBadge {
  font-size: 19.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}

.IconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.InfoField {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  background-color: #4d5dfa;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.InfoField_Contrast {
  background-color: #000;
}

.AlertText {
  color: red !important;
}

.PhoneLink {
  text-decoration: none;
}

.LeftPadding {
  padding-left: 20px;
}

.LeftPaddingSmall {
  padding-left: 10px;
}

.LeftPaddingTiny {
  padding-left: 5px;
}

.ModalEvaluation-EvaluateUser {
  padding-top: 0 !important;
}
.ModalEvaluation-EvaluateDriver {
  padding-top: 0 !important;
}

.BadgeInfoBlock {
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 2px #4d5dfa;
  max-width: 290px;
  padding: 10px 5px;
  font-weight: 600;
  font-size: 19.2px;
}
.BadgeInfoBlock-Title {
  color: #4d5dfa;
}

.RadioStatusesLabel {
  width: 100%;
  height: 30px;
  color: #000;
  font-weight: 600;
  border: 0;
  border-radius: 22px;
  text-align: center;
  line-height: 30px;
  display: flex;
  justify-content: left;
  align-items: center;
}
.RadioStatusesLabel_Status {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #4d5dfa;
  display: flex;
  justify-content: center;
  align-items: center;
}
.RadioStatusesLabel_StatusInner {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #4d5dfa;
}
.RadioStatusesLabel_Text {
  padding-left: 30px;
}

@media (max-width: 1320px) {
  .radio-directions-label__text {
    padding-left: 15px;
  }
}
@media (max-width: 1290px) {
  .radio-directions-label__text {
    padding-left: 10px;
  }
}
.radio-directions-label:hover {
  cursor: pointer;
}

.radio-directions-label_active {
  background-color: #FF0600;
  color: #252828;
}

.RadioStatusesInput {
  width: 1px !important;
  height: 1px !important;
  opacity: 0;
}

.DraggableReservationCard_Dragging {
  background-color: grey;
}
.DraggableReservationCard_Hovered {
  background-color: red;
}

.DispatcherDataInCard {
  font-size: 16px;
}

.StarIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.InputWithAlert {
  position: relative;
}

.Visible {
  width: 200px;
  height: 100px;
  background-color: red;
  padding-top: 75px;
}

.Hidden {
  display: none;
}

.NewIndicator {
  width: 15px;
  height: 15px;
  background: radial-gradient(100% 100% at 0% 0%, #fff 0%, #4d5dfa 100%);
  box-shadow: 0px 1px 5px #4d5dfa;
  border-radius: 50%;
}
.NewIndicator:hover {
  cursor: pointer;
}

.DataLabel {
  font-size: 16px;
  font-weight: 600;
}
@media (min-width: 1280px) {
  .DataLabel {
    font-size: 16px;
    font-weight: 600;
  }
}
.DataLabel_Centered {
  text-align: center;
}

.autocomplete-dropdown-container {
  position: absolute !important;
  z-index: 99;
}

.SectionNavMenu {
  height: calc(100vh - 125px);
  display: flex;
  flex-direction: column;
}
@media (min-width: 744px) {
  .SectionNavMenu {
    height: calc(100vh - 135px);
    overflow-y: auto;
  }
}
@media (min-width: 1280px) {
  .SectionNavMenu {
    height: calc(100vh - 100px);
  }
}
.SectionNavMenu-Item {
  min-height: 50px;
  border-bottom: 1px solid #cdd3dc;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  font-weight: 500;
  flex-grow: 1;
}
.SectionNavMenu-Item:hover {
  cursor: pointer;
  background-color: #4d5dfa;
}
.SectionNavMenu-Item:last-child {
  border-bottom: none;
}
@media (min-width: 744px) {
  .SectionNavMenu-Item {
    padding: 10px 20px;
    height: 100%;
  }
}
@media (min-width: 1280px) {
  .SectionNavMenu-Item {
    height: 100%;
  }
}
.SectionNavMenu-Icon {
  width: 10%;
}
@media (min-width: 744px) {
  .SectionNavMenu-Icon {
    max-width: 40px;
  }
}
@media (min-width: 1280px) {
  .SectionNavMenu-Icon {
    max-width: 100px;
  }
}
.SectionNavMenu-Text {
  padding-left: 10px;
  flex-grow: 1;
}
.SectionNavMenu-Link {
  text-decoration: none;
  color: #000;
}
.SectionNavMenu-Link:hover {
  color: #000;
}
.SectionNavMenu-NewIndicator {
  margin-right: 20px;
}

.MenuIcon {
  width: 100%;
  height: 100%;
}
.MenuIcon_Clickable:hover {
  cursor: pointer;
}
@media (min-width: 1280px) {
  .MenuIcon {
    width: 50px;
    height: 50px;
  }
}

.RefProgramDescription {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  text-align: justify;
}
@media (min-width: 1280px) {
  .RefProgramDescription {
    padding: 20px;
    font-size: 16px;
  }
}
.RefProgramDescription p {
  min-height: 1px;
}

.Page {
  padding-top: 50px;
  padding-bottom: 75px;
}
@media (min-width: 744px) {
  .Page {
    padding-top: 60px;
    padding-bottom: 75px;
  }
}
@media (min-width: 1280px) {
  .Page {
    padding-top: 100px;
    padding-bottom: 0;
  }
}

.Loader {
  height: 100%;
  min-height: 50vh;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.LoaderSmall {
  max-height: 20px;
}

.LoaderSmall .spinner-border {
  width: 20px;
  height: 20px;
}

.DropdownControl {
  background-color: #000 !important;
  border-color: #000 !important;
}

.UserLink:hover {
  cursor: pointer;
  color: #4d5dfa;
}

.TitleWithButton {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.SaveButton {
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  border-radius: 4px;
  border: 1px solid #cdd3dc;
  color: #4d5dfa;
  background-color: #cdd3dc;
}

.FullWidthButton {
  width: 100%;
}

.SwitchButton {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 24px;
  background-color: transparent;
  color: #4d5dfa;
  border: none;
}
@media (min-width: 744px) {
  .SwitchButton {
    width: 40px;
    height: 40px;
    font-size: 36px;
  }
}
.SwitchButton_Disabled {
  color: #cdd3dc;
}

.dropdown-menu {
  width: 100% !important;
}

.Footer-Nav .NavMenu-Item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.DataTableForAdmin {
  margin: 0 auto;
  border-collapse: collapse;
}
@media (min-width: 744px) {
  .DataTableForAdmin {
    width: 490px;
  }
}
@media (min-width: 1280px) {
  .DataTableForAdmin {
    width: 1200px;
  }
}
.DataTableForAdmin-Cell {
  padding: 5px 5px;
  height: 60px;
}
.DataTableForAdmin-CellWithButtons {
  display: flex;
  align-items: start;
  height: 100%;
}
.DataTableForAdmin-ButtonsBlock {
  margin-right: 1px;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1px;
}
.DataTableForAdmin-EntityButton {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #4d5dfa;
  border: 1px solid #4d5dfa;
}

thead tr:first-child {
  background-color: #4d5dfa;
  color: #fff;
}

.HeaderCell {
  text-align: left;
  height: 50px;
  min-width: 80px;
}
.HeaderCell:first-child {
  border-top-left-radius: 4px;
}
.HeaderCell:last-child {
  border-top-right-radius: 4px;
}
@media (min-width: 1280px) {
  .HeaderCell {
    min-width: 120px;
  }
}
.HeaderCell_W5 {
  width: 5%;
}
.HeaderCell_W6 {
  width: 6%;
}
.HeaderCell_W7 {
  width: 7%;
}
.HeaderCell_W8 {
  width: 8%;
}
.HeaderCell_W9 {
  width: 9%;
}
.HeaderCell_W10 {
  width: 10%;
}
.HeaderCell_W11 {
  width: 11%;
}
.HeaderCell_W12 {
  width: 12%;
}
.HeaderCell_W13 {
  width: 13%;
}
.HeaderCell_W14 {
  width: 14%;
}
.HeaderCell_W15 {
  width: 15%;
}
.HeaderCell_W16 {
  width: 16%;
}
.HeaderCell_W17 {
  width: 17%;
}
.HeaderCell_W18 {
  width: 18%;
}
.HeaderCell_W19 {
  width: 19%;
}
.HeaderCell_W20 {
  width: 20%;
}
.HeaderCell_W21 {
  width: 21%;
}
.HeaderCell_W22 {
  width: 22%;
}
.HeaderCell_W23 {
  width: 23%;
}
.HeaderCell_W24 {
  width: 24%;
}
.HeaderCell_W25 {
  width: 25%;
}
.HeaderCell_W26 {
  width: 26%;
}
.HeaderCell_W27 {
  width: 27%;
}
.HeaderCell_W28 {
  width: 28%;
}
.HeaderCell_W29 {
  width: 29%;
}
.HeaderCell_W30 {
  width: 30%;
}
.HeaderCell_W31 {
  width: 31%;
}
.HeaderCell_W32 {
  width: 32%;
}
.HeaderCell_W33 {
  width: 33%;
}
.HeaderCell_W34 {
  width: 34%;
}
.HeaderCell_W35 {
  width: 35%;
}
.HeaderCell_W36 {
  width: 36%;
}
.HeaderCell_W37 {
  width: 37%;
}
.HeaderCell_W38 {
  width: 38%;
}
.HeaderCell_W39 {
  width: 39%;
}
.HeaderCell_W40 {
  width: 40%;
}
.HeaderCell_W41 {
  width: 41%;
}
.HeaderCell_W42 {
  width: 42%;
}
.HeaderCell_W43 {
  width: 43%;
}
.HeaderCell_W44 {
  width: 44%;
}
.HeaderCell_W45 {
  width: 45%;
}
.HeaderCell_W46 {
  width: 46%;
}
.HeaderCell_W47 {
  width: 47%;
}
.HeaderCell_W48 {
  width: 48%;
}
.HeaderCell_W49 {
  width: 49%;
}
.HeaderCell_W50 {
  width: 50%;
}
.HeaderCell_W51 {
  width: 51%;
}
.HeaderCell_W52 {
  width: 52%;
}
.HeaderCell_W53 {
  width: 53%;
}
.HeaderCell_W54 {
  width: 54%;
}
.HeaderCell_W55 {
  width: 55%;
}
.HeaderCell_W56 {
  width: 56%;
}
.HeaderCell_W57 {
  width: 57%;
}
.HeaderCell_W58 {
  width: 58%;
}
.HeaderCell_W59 {
  width: 59%;
}
.HeaderCell_W60 {
  width: 60%;
}
.HeaderCell_W61 {
  width: 61%;
}
.HeaderCell_W62 {
  width: 62%;
}
.HeaderCell_W63 {
  width: 63%;
}
.HeaderCell_W64 {
  width: 64%;
}
.HeaderCell_W65 {
  width: 65%;
}
.HeaderCell_W66 {
  width: 66%;
}
.HeaderCell_W67 {
  width: 67%;
}
.HeaderCell_W68 {
  width: 68%;
}
.HeaderCell_W69 {
  width: 69%;
}
.HeaderCell_W70 {
  width: 70%;
}
.HeaderCell_W71 {
  width: 71%;
}
.HeaderCell_W72 {
  width: 72%;
}
.HeaderCell_W73 {
  width: 73%;
}
.HeaderCell_W74 {
  width: 74%;
}
.HeaderCell_W75 {
  width: 75%;
}
.HeaderCell_W76 {
  width: 76%;
}
.HeaderCell_W77 {
  width: 77%;
}
.HeaderCell_W78 {
  width: 78%;
}
.HeaderCell_W79 {
  width: 79%;
}
.HeaderCell_W80 {
  width: 80%;
}
.HeaderCell_W81 {
  width: 81%;
}
.HeaderCell_W82 {
  width: 82%;
}
.HeaderCell_W83 {
  width: 83%;
}
.HeaderCell_W84 {
  width: 84%;
}
.HeaderCell_W85 {
  width: 85%;
}
.HeaderCell_W86 {
  width: 86%;
}
.HeaderCell_W87 {
  width: 87%;
}
.HeaderCell_W88 {
  width: 88%;
}
.HeaderCell_W89 {
  width: 89%;
}
.HeaderCell_W90 {
  width: 90%;
}
.HeaderCell_W91 {
  width: 91%;
}
.HeaderCell_W92 {
  width: 92%;
}
.HeaderCell_W93 {
  width: 93%;
}
.HeaderCell_W94 {
  width: 94%;
}
.HeaderCell_W95 {
  width: 95%;
}

.TitleRow {
  background-color: #cdd3dc;
  font-weight: 600;
  color: #4d5dfa;
}
.TitleRow:hover {
  background-color: #000;
}

.DataRow {
  color: #fff;
  height: 40px;
  background-color: #000;
}
.DataRow_Last > td:first-child {
  border-bottom-left-radius: 4px;
}
.DataRow_Last > td:last-child {
  border-bottom-right-radius: 4px;
}
.DataRow:hover {
  background-color: #cdd3dc;
}

.rdrDefinedRangesWrapper {
  display: none !important;
}
@media (min-width: 1280px) {
  .rdrDefinedRangesWrapper {
    display: block !important;
  }
}

.editorClassName {
  background-color: #eee;
  min-height: 400px;
  border: 1px solid #cdd3dc;
}

.LeftMarginTiny {
  margin-left: 5px;
}

.ToggleButton {
  border-radius: 4px;
  background-color: #000;
  width: 150px;
  padding: 5px;
  color: #fff;
  text-align: center;
}
.ToggleButton:hover {
  cursor: pointer;
}

.UsersTable-Cell_Centered {
  text-align: center;
}

.Pointer:hover {
  cursor: pointer;
}

.DataListWithPagination {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.BlockLabel {
  font-weight: 600;
}

#react-select-1-listbox {
  z-index: 99;
}

#react-select-2-listbox {
  z-index: 99;
}

#react-select-3-listbox {
  z-index: 99;
}

#react-select-4-listbox {
  z-index: 99;
}

#react-select-5-listbox {
  z-index: 99;
}

#react-select-6-listbox {
  z-index: 99;
}

#react-select-7-listbox {
  z-index: 99;
}

#react-select-8-listbox {
  z-index: 99;
}

#react-select-9-listbox {
  z-index: 99;
}

#react-select-10-listbox {
  z-index: 99;
}

#react-select-11-listbox {
  z-index: 99;
}

#react-select-12-listbox {
  z-index: 99;
}

#react-select-13-listbox {
  z-index: 99;
}

#react-select-14-listbox {
  z-index: 99;
}

#react-select-15-listbox {
  z-index: 99;
}

#react-select-16-listbox {
  z-index: 99;
}

#react-select-17-listbox {
  z-index: 99;
}

#react-select-18-listbox {
  z-index: 99;
}

#react-select-19-listbox {
  z-index: 99;
}

#react-select-20-listbox {
  z-index: 99;
}

#react-select-21-listbox {
  z-index: 99;
}

#react-select-22-listbox {
  z-index: 99;
}

#react-select-23-listbox {
  z-index: 99;
}

#react-select-24-listbox {
  z-index: 99;
}

#react-select-25-listbox {
  z-index: 99;
}

#react-select-26-listbox {
  z-index: 99;
}

#react-select-27-listbox {
  z-index: 99;
}

#react-select-28-listbox {
  z-index: 99;
}

#react-select-29-listbox {
  z-index: 99;
}

#react-select-30-listbox {
  z-index: 99;
}

#react-select-31-listbox {
  z-index: 99;
}

#react-select-32-listbox {
  z-index: 99;
}

#react-select-33-listbox {
  z-index: 99;
}

#react-select-34-listbox {
  z-index: 99;
}

#react-select-35-listbox {
  z-index: 99;
}

#react-select-36-listbox {
  z-index: 99;
}

#react-select-37-listbox {
  z-index: 99;
}

#react-select-38-listbox {
  z-index: 99;
}

#react-select-39-listbox {
  z-index: 99;
}

#react-select-40-listbox {
  z-index: 99;
}

#react-select-41-listbox {
  z-index: 99;
}

#react-select-42-listbox {
  z-index: 99;
}

#react-select-43-listbox {
  z-index: 99;
}

#react-select-44-listbox {
  z-index: 99;
}

#react-select-45-listbox {
  z-index: 99;
}

#react-select-46-listbox {
  z-index: 99;
}

#react-select-47-listbox {
  z-index: 99;
}

#react-select-48-listbox {
  z-index: 99;
}

#react-select-49-listbox {
  z-index: 99;
}

#react-select-50-listbox {
  z-index: 99;
}

#react-select-51-listbox {
  z-index: 99;
}

#react-select-52-listbox {
  z-index: 99;
}

#react-select-53-listbox {
  z-index: 99;
}

#react-select-54-listbox {
  z-index: 99;
}

#react-select-55-listbox {
  z-index: 99;
}

#react-select-56-listbox {
  z-index: 99;
}

#react-select-57-listbox {
  z-index: 99;
}

#react-select-58-listbox {
  z-index: 99;
}

#react-select-59-listbox {
  z-index: 99;
}

#react-select-60-listbox {
  z-index: 99;
}

#react-select-61-listbox {
  z-index: 99;
}

#react-select-62-listbox {
  z-index: 99;
}

#react-select-63-listbox {
  z-index: 99;
}

#react-select-64-listbox {
  z-index: 99;
}

#react-select-65-listbox {
  z-index: 99;
}

#react-select-66-listbox {
  z-index: 99;
}

#react-select-67-listbox {
  z-index: 99;
}

#react-select-68-listbox {
  z-index: 99;
}

#react-select-69-listbox {
  z-index: 99;
}

#react-select-70-listbox {
  z-index: 99;
}

#react-select-71-listbox {
  z-index: 99;
}

#react-select-72-listbox {
  z-index: 99;
}

#react-select-73-listbox {
  z-index: 99;
}

#react-select-74-listbox {
  z-index: 99;
}

#react-select-75-listbox {
  z-index: 99;
}

#react-select-76-listbox {
  z-index: 99;
}

#react-select-77-listbox {
  z-index: 99;
}

#react-select-78-listbox {
  z-index: 99;
}

#react-select-79-listbox {
  z-index: 99;
}

#react-select-80-listbox {
  z-index: 99;
}

#react-select-81-listbox {
  z-index: 99;
}

#react-select-82-listbox {
  z-index: 99;
}

#react-select-83-listbox {
  z-index: 99;
}

#react-select-84-listbox {
  z-index: 99;
}

#react-select-85-listbox {
  z-index: 99;
}

#react-select-86-listbox {
  z-index: 99;
}

#react-select-87-listbox {
  z-index: 99;
}

#react-select-88-listbox {
  z-index: 99;
}

#react-select-89-listbox {
  z-index: 99;
}

#react-select-90-listbox {
  z-index: 99;
}

#react-select-91-listbox {
  z-index: 99;
}

#react-select-92-listbox {
  z-index: 99;
}

#react-select-93-listbox {
  z-index: 99;
}

#react-select-94-listbox {
  z-index: 99;
}

#react-select-95-listbox {
  z-index: 99;
}

#react-select-96-listbox {
  z-index: 99;
}

#react-select-97-listbox {
  z-index: 99;
}

#react-select-98-listbox {
  z-index: 99;
}

#react-select-99-listbox {
  z-index: 99;
}

#react-select-100-listbox {
  z-index: 99;
}

.NotificationsLink {
  color: #fff;
}
.NotificationsLink_HasNew {
  color: #4d5dfa;
}
.NotificationsLink:hover {
  cursor: pointer;
}

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 1.25em;
  font-weight: 500;
  color: #000;
  box-sizing: border-box;
}
@media (min-width: 1280px) {
  body {
    font-size: 24px;
  }
}

.root {
  box-sizing: border-box;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-color: #000 #ffffff;
  scrollbar-width: thin;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 4px;
  border: 2px solid #ffffff;
}

.scale {
  transition: transform 0.3s linear;
}
.scale:hover {
  transform: scale(1.04);
}

