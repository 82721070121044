@import './vars';
@import './mixins';

.FiltersControls {
  &-SearchBlock {
    margin-bottom: $standard-gap / 2;
    @include onDesktop {
      width: 30%;
    }
  }

  &-DateFilter {
    margin-bottom: $standard-gap / 2;
    @include onDesktop {
      width: 25%;
    }
  }

  &-PriceFilter {
    margin-bottom: $standard-gap / 2;
    @include onDesktop {
      width: 25%;
    }
  }

  &-StatusFilter {
    @include onDesktop {
      width: 15%;
    }
  }
}
