@import './vars';
@import './mixins';

.StartPage {
  min-height: 100vh;
  height: 100vh;
  padding-top: $header-height;
  box-sizing: border-box;

  @include onTablet {
    padding-top: $header-height-tablet;
  }

  @include onDesktop {
    padding-top: $header-height-desktop;
    background: url('../../public/img/greeting-bg.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &-Content {
    margin: 0 auto;
    min-width: $content-min-width;
    width: $content-width;
    max-width: $content-max-width;

    @include onDesktop {
      margin-top: 200px;
      max-width: $content-max-width-desktop;
    }
  }

  &-Title {
    margin-bottom: $standard-gap;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
      font-size: $large-font-size-desktop * 1.3;
      color: $light-font-color;
      text-transform: uppercase;
    }
  }

  &-Description {
    margin-bottom: $standard-gap * 2;
    text-align: center;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
      color: $light-font-color;
    }
  }

  &-LoginButton {
    margin: 0 auto $standard-gap;
    width: 250px;

    @include onDesktop {
      margin: 0 auto $standard-gap-desktop;
    }
  }

  &-RegisterButton {
    margin: 0 auto $standard-gap;
    width: 250px;
    background-color: $secondary-bg-color;

    @include onDesktop {
      margin: 0 auto $standard-gap-desktop;
    }
  }

  &-PolicyLink {
    text-decoration: none;
    display: flex;
    justify-content: center;

    color: $light-font-color;

    &:hover {
      color: $elements-dark-bg-color;
    }
  }
}

.GreetingImageContainer {
  width: 100%;
  height: 400px;
  max-height: 50vh;
  background-image: url('../../public/img/greeting-picture.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @include onTablet {
    display: none;
  }

  @include onDesktop {
    display: none;
  }
}
