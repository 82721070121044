@import './vars';
@import './mixins';

.PassengersInTripList {
  &-Head {
    align-items: center;
    margin-bottom: $standard-gap / 2;
  }

  &-StatusTitle {
    margin-bottom: 0;
  }

  &-EditButton {
    font-size: $small-font-size;
    max-width: 120px;
    line-height: 1em;
    height: $small-control-height;

    &:hover {
      cursor: pointer;
    }

    @include onDesktop {
      font-size: $small-font-size-desktop;
      max-width: 200px;
    }
  }
}
