@import './vars';
@import './mixins';

.TripsPageMenu {
  min-height: 100%;
  height: 100%;

  //@include onTablet {
  //  min-height: 100%;
  //  height: 100%;
  //}

  @include onDesktop {
    //min-height: 100%;
    height: 100%;
    padding-bottom: 0;
  }

  &-Nav {
    margin: 0 auto;
  }
}
