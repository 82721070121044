@import './vars';
@import './mixins';

.Statistic {
  &-Item {
    margin-bottom: $standard-gap;
  }

  &-DateRangeSelection {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid $additional-bg-color;
  }

  &-CalendarButtons {
    margin: 0 auto;
    padding-bottom: $standard-gap;
    max-width: $content-max-width;
  }
}
