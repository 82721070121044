@import './vars';
@import './mixins';

.TestimonialsList {
  margin: 0 auto; // replace later to upper class
  padding-top: $header-height + $standard-gap;
  display: grid;
  gap: $standard-gap;
  grid-template-columns: repeat(auto-fill, minmax($content-min-width, 1fr));

  min-width: $content-min-width;
  width: $content-width;
  max-width: $content-max-width;

  @include onDesktop {
    width: 100%;
  }
}
