@import './vars';
@import './mixins';

.LoginContainer {
  display: flex;
  flex-direction: column;

  @include onTablet {
    //flex-direction: row;
  }

  @include onDesktop {
    margin: 0 auto;
    //padding-top: $standard-gap-desktop * 2;
    flex-direction: row;
    width: $content-max-width-desktop;
  }

  &-ImageContainer {
    margin-bottom: $standard-gap;
    align-self: center;

    @include onTablet {
      //flex-grow: 1;
      //order: 1;
    }

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
      flex-grow: 1;
      order: 1;
    }
  }

  &-LoginForm {
    margin: 0 auto;
  }
}

.LoginImage {
  @include onDesktop {
    width: 100%;
  }
}

.LoginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: $content-min-width;
  width: $content-width;
  max-width: $content-max-width;

  @include onDesktop {
    width: $content-max-width;
  }

  &-Item {
    position: relative;
    margin-bottom: $standard-gap;
    width: 100%;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
      width: $content-width;
      max-width: $content-max-width;
    }
  }

  &-Label {
    display: block;
    margin-bottom: $standard-gap / 2;
    text-align: left;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop / 2;
    }
  }

  &-InputContainer {
    position: relative;
  }

  &-Input {
    padding-left: 44px;
  }

  &-InputIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }

  &-InputIconRight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }

  &-TextContainer {
    margin: 0;
    display: flex;
    justify-content: right;
  }

  &-Text {
    text-align: right;
    padding-right: 15px;
    text-decoration: none;
    color: $pale-font-color;
    font-weight: $pale-font-weight;

    @include onDesktop {
      font-size: $small-font-size-desktop;
    }
  }

  &-RegisterButton {
    margin: 0 auto;
    background-color: $secondary-bg-color;

    @include onDesktop {
      margin: 0 auto $standard-gap-desktop;
    }
  }

  &-RulesLink {
    margin-bottom: $standard-gap * 1.5;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop * 1.5;
    }
  }

  &-SideRegisterServices {
    margin-bottom: $standard-gap / 2;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
    }
  }
}

.EyeIcon {
  width: 24px;
  height: 20px;

  &:hover {
    cursor: pointer;
  }
}

.PasswordInputWithText {
  font-weight: $title-font-weight;
  color: $elements-dark-bg-color;
}

