//#region Colors
$main-bg-color: #fff;
$secondary-bg-color: #000;
$additional-bg-color: #cdd3dc;
$elements-dark-bg-color: #4d5dfa;
$elements-pale-bg-color: #4d54faaa;
$elements-light-bg-color: #fff;
$main-font-color: #000;
$pale-font-color: rgba(0, 0, 0, 0.7);
$light-font-color: #fff;
$standard-border-color: #cacaca;
//#endregion

//#region fonts
$main-font-family: 'Inter', sans-serif;
//#endregion

//#region sizes
$main-font-weight: 500;
$bold-font-weight: 600;
$title-font-weight: 700;
$pale-font-weight: 400;
$small-font-size: 14px;
$main-font-size: 16px;
$large-font-size: 28px;
$small-font-size-desktop: 16px;
$main-font-size-desktop: 24px;
$large-font-size-desktop: 36px;

$header-height: 50px;
$header-height-tablet: 60px;
$header-height-desktop: 100px;

$footer-height: 75px;
$footer-height-tablet: 75px;
$footer-height-desktop: 100px;

$pagination-height: 50px;
$pagination-height-desktop: 60px;

$control-width: 100%;
$small-control-height: 40px;
$control-height: 50px;

$control-border-radius: 32px;
$control-secondary-border-radius: 4px;

$content-min-width: 330px;
$content-width: 88vw;
$content-max-width: 490px;

$content-max-width-desktop: 1200px;

$standard-gap: 20px;
$standard-gap-desktop: 20px;

$checkbox-size: 20px;

$scroll-bar-width: 8px;
//#endregion
