@import './vars';
@import './mixins';

.AddVehicle {
  min-width: $content-min-width;
  width: $content-width;
  max-width: $content-max-width;

  @include onDesktop {
    width: $content-max-width;
  }

  &-SchemeDraw {
    margin-bottom: $standard-gap;
  }
}

.AddVehicleForm {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include onDesktop {
    width: $content-max-width;
  }

  &-Item {
    position: relative;
    margin-bottom: $standard-gap;
    width: 100%;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
      width: $content-width;
      max-width: $content-max-width;
    }
  }

  &-Label {
    display: block;
    margin-bottom: $standard-gap / 2;
    text-align: left;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop / 2;
    }
  }

  &-InputContainer {
    position: relative;
  }

  &-Input {
    padding-left: 44px;
  }

  &-InputIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }

  &-InputIconRight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }

  &-TextContainer {
    margin: 0;
    display: flex;
    justify-content: right;
  }

  &-Text {
    text-align: right;
    padding-right: 15px;
    text-decoration: none;
    color: $pale-font-color;
    font-weight: $pale-font-weight;

    @include onDesktop {
      font-size: $small-font-size-desktop;
    }
  }

  &-CheckboxWithText {
    //padding-top: 10px;
  }

  &-PlaceSelect {
    padding: 0 0 0 44px;
    border: 1px solid $pale-font-color !important;
    border-radius: $control-border-radius !important;
    color: $pale-font-color !important;
    font-weight: $pale-font-weight;
    font-size: $small-font-size;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
  }

  &-FreePlacingText {
    font-size: $main-font-size;
    padding-left: 10px;

    @include onDesktop {
      font-size: $main-font-size-desktop;
    }
  }

  &-ContrastButton {
    background-color: $secondary-bg-color;
  }

  &-PlacesConstructor {
    margin-bottom: $standard-gap * 2;
  }
}

.AddVehicleForm-Item .FormControl_Error {
  border: 1px solid red !important;
}
