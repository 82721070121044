@import './vars';
@import './mixins';

.TripStatusBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: $standard-gap / 2;
  padding-right: $standard-gap / 2;
  min-width: 100px;
  border-radius: $control-border-radius;
  border: 1px solid $elements-dark-bg-color;
  height: $standard-gap;
  color: $elements-dark-bg-color;
  font-size: $small-font-size;
  line-height: $standard-gap;
}
