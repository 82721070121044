@import './vars';
@import './mixins';

.CardsListScreen {
  min-height: 75vh;
  width: 100%;

  @include  onDesktop {
    min-height: 65vh;
  }

  &_Reduced {
    min-height: 40vh;

    @include onDesktop {
      min-height: 50vh;
    }
  }

  &-Item {
    margin-bottom: $standard-gap;
  }

  &-NoEntriesText {
    text-align: center;
    color: $pale-font-color;
    font-weight: $main-font-weight;
    //position: absolute;
    //top: $header-height * 2;
    min-width: $content-min-width;
    width: $content-width;
    max-width: $content-max-width;
    margin: $standard-gap / 2 auto;

    @include onDesktop {
      //left: 50%;
      //transform: translateX(-50%);
      //top: $header-height-tablet * 2;
    }
  }

  &_Empty {
    background: url('../../public/img/no-passengers.webp');
    background-repeat: no-repeat;
    background-position: center;
  }

  &-BadgeInfoBlock {
    margin: $standard-gap / 2 auto $standard-gap / 2;
  }
}

.TripCardsList {
  //padding-bottom: $standard-gap + $control-height;
  display: grid;
  gap: $standard-gap;
  grid-template-columns: repeat(auto-fill, minmax($content-min-width, 1fr));

  @include onDesktop {
    width: $content-max-width-desktop;
    max-width: $content-max-width-desktop;
  }

  &-AddButton {
    position: fixed;
    bottom: $footer-height + $standard-gap * 4;
    min-width: $content-min-width;
    width: $content-width;
    max-width: $content-max-width;
    z-index: 99;

    @include onTablet {
      bottom: 20vh;
    }

    @include onDesktop {
      left: 50%;
      transform: translateX(-50%);
      bottom: $standard-gap * 5;

      &:hover {
        transform: translateX(-50%) scale(1.01);
      }
    }
  }
}
