@import './vars';
@import './mixins';

.PlacesInTripEditing {
  &-Head {
    margin-bottom: $standard-gap;
    align-items: center;
  }

  &-VehicleBody {
    margin: 0 auto;
  }

  &-CellContainer {
    position: relative;
  }

  &-AllPlacesCheckBox {
    width: 15px;
    height: 15px;
  }

  &-AllPlacesLabel {
    padding-left: $standard-gap / 2;
  }

  &-Checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    visibility: hidden;
  }

  &-Place {
    box-sizing: border-box;

    &:hover {
      cursor: pointer;
    }

    &_Occupied {
      background-color: $elements-dark-bg-color;
    }
  }
}
