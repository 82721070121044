@import './vars';
@import './mixins';

.RulesAndConfidentialityPolicy {
  padding-top: $header-height;
  min-width: $content-min-width;
  width: $content-width;
  max-width: $content-max-width;

  @include onTablet {
    padding-top: $header-height-tablet;
  }

  @include onDesktop {
    width: $content-max-width-desktop;
    max-width: $content-max-width-desktop;
    padding-top: $header-height-desktop;
  }

  &-Title {
    margin-bottom: $standard-gap;
    padding-top: $standard-gap;
    font-size: 1.1 * $main-font-size;

    @include onDesktop {
      padding: $standard-gap-desktop;
    }
  }

  &-Text {
    margin-bottom: $standard-gap;
    font-size: $small-font-size;
    font-weight: $pale-font-weight;
    text-align: justify;

    @include onDesktop {
      padding: $standard-gap-desktop;
      font-size: $small-font-size-desktop;
    }
  }

  &-BackButton {
    @include onDesktop {
      margin-left: $standard-gap-desktop;
      width: 200px;
    }
  }
}
