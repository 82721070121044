@import './vars';
@import './mixins';

.CopyToBufferButton {
  font-size: 24px;
  color: $main-bg-color;

  &:hover {
    cursor: pointer;
  }
}

