@import './vars';
@import './mixins';

.ModalPlaceDescription {
  &-Form {
    margin: 0 auto;
  }
}

.PlaceDescriptionForm {
  &-Item {
    margin-bottom: $standard-gap;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
    }
  }

  &-Button {
    width: 48%;

    &_Contrast {
      background-color: $secondary-bg-color;
    }
  }

  &-Label {
    display: block;
    margin-bottom: $standard-gap / 2;
    text-align: left;
    font-weight: $main-font-weight;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop / 2;
    }
  }
}
