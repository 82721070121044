@import './vars';
@import './mixins';

.DebtsList {
  //padding-bottom: $standard-gap + $control-height;
  display: grid;
  gap: $standard-gap;
  grid-template-columns: repeat(auto-fill, minmax($content-min-width, 1fr));
  padding-bottom: $standard-gap * 1.2;

  @include onDesktop {
    width: $content-max-width-desktop;
    max-width: $content-max-width-desktop;
  }
}
