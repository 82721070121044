@import './vars';
@import './mixins';

.RouteOnMapContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.RouteOnMap {
  height: calc(100vh - #{($header-height + $footer-height)} - 100px);
  width: 100%;

  @include onDesktop {
    height: 600px;
    width: 600px;
  }
}
