@import './vars';
@import './mixins';

.MyDrivers {
  padding-top: $standard-gap;

  &-Title {
    display: none;

    @include onDesktop {
      display: block;
    }
  }

  &-DriversList {
    //margin: 0 auto;
  }

  &-InviteDriver {
    margin: 0 auto;
  }

  &-Driver {
    margin: 0 auto;
    width: $content-width;
    max-width: $content-max-width;

    @include onDesktop {
      width: $content-max-width-desktop;
      max-width: $content-max-width-desktop;
    }
  }
}

.DriversList {
  display: grid;
  gap: $standard-gap;
  grid-template-columns: repeat(auto-fill, minmax($content-min-width, 1fr));
  width: $content-width;
  max-width: $content-max-width;

  @include onDesktop {
    width: $content-max-width-desktop;
    max-width: $content-max-width-desktop;
  }

  &-AddDriverButton {
    position: fixed;
    bottom: $footer-height * 3;
    min-width: $content-min-width;
    width: $content-width;
    max-width: $content-max-width;
    z-index: 99;
    left: 50%;
    transform: translateX(-50%);

    @include onDesktop {
      bottom: $footer-height * 2.5;
    }

    &:hover {
      left: 50%;
      transform: scale(1.01) translateX(-50%);
    }
  }
}

