@import './vars';
@import './mixins';

.NotePad {
  postion: relative;
  padding-top: $header-height;
  padding-bottom: $footer-height;
  min-height: 100vh;

  @include onTablet {
    padding-top: $header-height-tablet;
    padding-bottom: $footer-height-tablet;
  }

  @include onDesktop {
    padding-top: $header-height-desktop;
    padding-bottom: 0;
  }

  &-TabControls {
    padding-top: $standard-gap;
  }

  &-Title {
    display: none;

    @include onTablet {
      //padding-top: $header-height-tablet + $standard-gap;
      //min-height: calc(100vh - $footer-height-tablet);
    }

    @include onDesktop {
      display: block;
      //padding-top: $header-height-desktop + $standard-gap;
      //min-height: 100vh;
    }
  }

  &-Content {
    margin: 0 auto;
    min-width: $content-min-width;
    width: $content-width;
    max-width: $content-max-width;

    @include onDesktop {
      min-width: $content-max-width-desktop;
      width: $content-max-width-desktop;
      max-width: $content-max-width-desktop;
    }
  }

  &-CreateTripOrder {

    @include  onDesktop {
      margin: 0 auto;
      width: $content-max-width;
    }
  }
};
