@import './vars';
@import './mixins';

.AddToBalance {
  min-width: $content-min-width;
  width: $content-width;
  max-width: $content-max-width;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include onDesktop {
    width: $content-max-width;
  }

  &-Item {
    margin-bottom: $standard-gap;
    width: 100%;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
      //width: $content-width;
      max-width: $content-max-width;
    }
  }

  &-Label {
    display: block;
    margin-bottom: $standard-gap / 2;
    text-align: left;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop / 2;
    }
  }

  &-FlexBlock {
    align-items: end;
  }

  &_Disabled {
    //color: $additional-bg-color;
    background-color: $additional-bg-color;

    &:hover {
      transform: scale(1);
    }
  }
}
