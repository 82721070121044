@import './vars';
@import './mixins';

.LocationSearch {
  &_Suggestions {
    position: absolute;
    background-color: $main-bg-color;
    width: $control-width;

    &:hover {
      cursor: pointer;
    }
  }
}
