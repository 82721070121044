@import './vars';
@import './mixins';

.DriverCard {
  position: relative;
  padding: $standard-gap / 2;
  min-width: $content-min-width;
  //width: $content-width;
  max-width: $content-max-width;
  //background-color: $secondary-bg-color;
  //color: $light-font-color;
  border-radius: $control-secondary-border-radius;
  border: 2px solid $elements-dark-bg-color;

  &:hover {
    cursor: pointer;
  }

  @include onDesktop {
    min-width: $content-min-width;
  }

  &-Item {
    margin-bottom: $standard-gap / 2;
  }

  &-Status {
    position: absolute;
    min-width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5 * $standard-gap;
    top: 0;
    right: 0;
    background-color: $secondary-bg-color;
    border-bottom-left-radius: $control-border-radius;
    color: $light-font-color;
    font-size: $small-font-size;

    &_Confirmed {
      background-color: $elements-dark-bg-color;
    }
  }
}
