@import './vars';
@import './mixins';

.VehicleCard {
  padding: $standard-gap / 2;
  min-width: $content-min-width;
  //width: $content-width;
  max-width: $content-max-width;
  //background-color: $secondary-bg-color;
  //color: $light-font-color;
  border-radius: $control-secondary-border-radius;
  border: 2px solid $elements-dark-bg-color;

  &:hover {
    cursor: pointer;
  }

  @include onDesktop {
    min-width: $content-min-width;
  }

  &-Item {
    margin-bottom: $standard-gap / 2;
  }
}
