@import './vars';
@import './mixins';

.SideRegisterServices {
  width: 100%;

  &-TextContainer {
    margin-bottom: $standard-gap;
    padding: 0 8px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
    }
  }

  &-Line {
    background-color: $pale-font-color;
    flex-grow: 1;
    width: 100%;
    height: 1.5px;

    @include onDesktop {
      width: 90px;
    }
  }

  &-Text {
    min-width: 100px;
    font-weight: $pale-font-weight;
    font-size: $small-font-size;
    text-align: center;

    @include onDesktop {
      font-size: $small-font-size-desktop;
    }
  }

  &-Buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-Button {
    width: 100px;
    border: 1px solid $pale-font-color;
    background-color: transparent;

    @include onDesktop {
      width: $content-max-width / 4;
    }
  }
}
