@import './vars';
@import './mixins';

.Trips {
  min-height: calc(100vh - #{$footer-height});
  padding-top: $header-height;
  padding-bottom: $footer-height;

  &-Screen {
    width: $content-width;
    max-width: $content-max-width;
    margin: 0 auto;
  }

  &-Title {
    display: none;
  }

  @include onTablet {
    min-height: calc(100vh - #{$footer-height-tablet});
    padding-top: $header-height-tablet;
    padding-bottom: $footer-height-tablet;
  }

  @include onDesktop {
    min-height: 100vh;
    padding-top: $header-height-desktop;
    padding-bottom: 0;
  }

  &-Nav {
    margin: 0 auto;
  }

  &-Content {
    position: relative;
  }

  &-BackArrow {
    position: absolute;
    left: 10%;
    top: -40px;
    width: 30px;
    height: 30px;
    background: url('../../public/img/profile-menu/back-arrow-icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &-RulesAndConfidentialPolicy {
    margin: 0 auto;
  }

  &-AddToBalance {
    margin: 0 auto;
  }

  &-InviteDriver {
    padding-top: $standard-gap;
    margin: 0 auto;
  }

  &-WithdrawFromBalance {
    margin: 0 auto;
  }
}

.NavMenuTrips {
  display: flex;
  flex-direction: column;

  @include onDesktop {
    width: $content-max-width-desktop;
    max-width: $content-max-width-desktop;
  }

  &-Item {
    height: $control-height;
    border-bottom: 1px solid $additional-bg-color;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    padding: $standard-gap / 4 $standard-gap;
    font-weight: $main-font-weight;

    &:hover {
      cursor: pointer;
      background-color: $elements-dark-bg-color;
    }
  }

  &-Icon {
    width: 10%;
  }

  &-Text {
    padding-left: 10px;
    flex-grow: 1;
  }
}
