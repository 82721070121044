@import './vars';
@import './mixins';

.Login {
  height: calc(100vh - #{$header-height});
  padding-top: $header-height;
  box-sizing: border-box;

  @include onTablet {
    padding-top: $header-height-tablet;
    height: calc(100vh - #{$header-height-tablet});
  }

  @include onDesktop {
    padding-top: $header-height-desktop;
    height: calc(100vh - #{$header-height-desktop});
  }
}
