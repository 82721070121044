@import './vars';
@import './mixins';

.App {
  box-sizing: border-box;
  min-height: 100vh;

  &-RecoverPassword {
    margin: 0 auto;
  }

  &-RecoverPasswordScreen {
    margin: 0 auto;
  }

  &-RulesAndConfidentialityPolicy {
    margin: 0 auto;
  }

  &-Exchange {
    margin: 0 auto;
  }

  &-Screen {
    margin: 0 auto;
    min-width: $content-min-width + $scroll-bar-width;
    width: $content-width;
    max-width: $content-max-width;

    @include onDesktop {
      min-width: $content-max-width-desktop;
      width: $content-max-width-desktop;
      max-width: $content-max-width-desktop;
    }

    &_Narrow {
      @include onDesktop {
        margin: 0 auto;
        min-width:$content-max-width;
        width: $content-max-width;
        max-width: $content-max-width;
      }
    }
  }

  &-Header {
    position: fixed;
    z-index: 99;
  }

  &-RulesAndPolicy {
    margin: 0 auto;
  }
}
