@import "./vars";
@import "./mixins";

.PlacesSuggestions {
  list-style-type: none;
  padding-left: 0;
  width: 100%;
  max-width: $content-width;
  font-size: $small-font-size;
  line-height: 1.1em;
  z-index: 99;

  @include onDesktop {
    max-width: $content-max-width;
  }

  &-Item {
    padding-top: $standard-gap / 4;
    padding-bottom: $standard-gap / 4;

    &:hover {
      background-color: $additional-bg-color;
    }

    &_Active {
      background-color: $additional-bg-color;
    }
  }
}
