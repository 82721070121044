@import './vars';
@import './mixins';

.ModalShowVehicleScheme {
  &-VehicleBody {
    margin: 0 auto;
  }

  &-Button {
    &_Contrast {
      background-color: $secondary-bg-color;
    }
  }
}
