@import './vars';
@import './mixins';

.TransactionsHistory {
  padding-bottom: $footer-height;

  @include onDesktop {
    padding-bottom: 0;
  }

  &-Item {
    margin: 0 auto;
    padding: $standard-gap / 2 0;
    min-width: $content-min-width;
    width: $content-width;
    max-width: $content-max-width;
    line-height: 1.2em;
    font-weight: $main-font-weight;
  }

  &-Date {
    margin-bottom: $standard-gap;
    font-size: $small-font-size;
    color: $pale-font-color;
    text-align: center;
  }

  &-Description {
    margin-bottom: $standard-gap / 4;
    display: flex;
    justify-content: space-between;
    font-size: $main-font-size;
  }

  &-DescriptionText {

  }

  &-DescriptionAmount {
    //color: $elements-dark-bg-color;
    color: #50F078;

    &_Negative {
      color: red;
    }

    &_PositiveSpecial {
      color: $pale-font-color;
    }
  }

  &-CounterParty {
    font-size: $small-font-size;
    color: $pale-font-color;
  }
}
