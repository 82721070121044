@import './vars';
@import './mixins';

.ReplaceTripOrderScreen {
  @include onDesktop {
    width: $content-width;
    max-width: $content-max-width;
    margin: 0 auto;
  }

  &-Item {
    margin-bottom: $standard-gap;
    width: 100%;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
      width: $content-width;
      max-width: $content-max-width;
    }
  }

  &-Label {
    display: block;
    margin-bottom: $standard-gap / 2;
    text-align: left;

    &:hover {
      cursor: pointer;
    }

    @include onDesktop {
      margin-bottom: $standard-gap-desktop / 2;
    }
  }

  &-IconContainer {
    flex-grow: 1;
    display: flex;
    justify-content: right;
  }

  &-OptionText {
    position: relative;
    padding-left: $standard-gap;

    &_Bold {
      font-weight: $bold-font-weight;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-Block {
    padding-top: $standard-gap / 2;
    align-items: center;
    line-height: 1em;
  }
}
