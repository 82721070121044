@import './vars';
@import './mixins';

.NotificationDetails {
  padding-top: $standard-gap;

  &-Head {
    position: relative;
  }

  &-Item {
    margin-bottom: $standard-gap;
  }

  &-Text {
    text-align: justify;
  }

  &-CreatingTime {
    border-radius: $control-secondary-border-radius;
    background-color: $elements-dark-bg-color;
    width: 150px;
    padding: $standard-gap / 4;
    color: $light-font-color;
    text-align: center;
  }
}
