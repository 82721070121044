@import './vars';
@import './mixins';

.TestimonialCard {
  position: relative;
  min-width: $content-min-width;
  max-width: $content-max-width;
  border-radius: $control-secondary-border-radius;
  border: 2px solid $elements-dark-bg-color;
  padding: $standard-gap / 4;

  @include onDesktop {
    min-width: $content-min-width;
  }

  &-Head {
    padding: $standard-gap / 4 $standard-gap / 2;
    font-weight: bold;
  }

  &-HeadItem {
    display: flex;
    align-items: center;
    padding-right: $standard-gap;
  }

  &-Text {
    padding: $standard-gap / 4 $standard-gap / 2;
    min-height: $standard-gap * 3;
    border-radius: $control-secondary-border-radius;
    background-color: $additional-bg-color;
  }
}
