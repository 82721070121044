@import './vars';
@import './mixins';

.CounterPartyData {
  //padding-top: 30vh;
  padding-top: $standard-gap;

  &-Item {
    margin-bottom: $standard-gap;
  }

  &-Details {
    margin: 0 auto;
  }
  //&-DetailsBlock {
  //  top: $header-height + $standard-gap;
  //  left: 6vw;
  //  width: 88vw;
  //  position: fixed;
  //  z-index: 99;
  //  background-color: $main-bg-color;
  //}
}
