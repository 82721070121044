@import './vars';
@import './mixins';

.ChangePassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: $content-min-width;
  width: $content-width;
  max-width: $content-max-width;

  @include onDesktop {
    min-height: 100vh;
    width: $content-max-width;
    padding-top: $header-height-desktop;
  }

  &-Item {
    position: relative;
    margin-bottom: $standard-gap;
    width: 100%;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop;
      width: $content-width;
      max-width: $content-max-width;
    }
  }

  &-Label {
    display: block;
    margin-bottom: $standard-gap / 2;
    text-align: left;

    @include onDesktop {
      margin-bottom: $standard-gap-desktop / 2;
    }
  }
  &-InputContainer {
    position: relative;
  }

  &-Input {
    padding-left: 44px;
  }

  &-InputIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }

  &-InputIconRight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }

  &-TextContainer {
    display: flex;
    justify-content: right;
  }

  &-Text {
    text-align: right;
    padding-right: 15px;
    text-decoration: none;
    color: $elements-dark-bg-color;

    &_Tiny {
      font-size: $small-font-size;
      color: $pale-font-color;
      font-weight: $pale-font-weight;

      @include onDesktop {
        font-size: $small-font-size;
      }
    }

    @include onDesktop {
      font-size: $small-font-size-desktop;
    }
  }

  &-Notification {
    padding: $standard-gap / 2;
    min-height: 100px;
    justify-content: center;
    text-align: center;
  }

  &-NoUserAlert {
    position: absolute;
    top: $control-height;
    left: 0;
    font-size: $small-font-size;
  }

  &-WrongCodeAlert {
    position: absolute;
    top: $control-height;
    left: 0;
  }

  &-PasswordsNoMatchAlert {
    position: absolute;
    top: $control-height;
    left: 0;
  }
}
